import { colors, customBezierEase } from 'shared/variables';
import styled from 'styled-components';
import { Eyebrow, H3, Body, bodyCopy } from 'shared/typography';

export const Container = styled.div`
  border-bottom: 2px solid ${colors.brownLight};
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
  align-items: flex-end;
  > div:last-child {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: end;
    gap: 15px;
  }
`;

export const Type = styled(Eyebrow)`
  color: ${colors.brownMedium};
  margin: 0 0 6px;
`;

export const Title = styled(H3)`
  color: ${colors.brownMedium};
  margin: 0;
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  /* margin-right: 24px; */
`;

export const Detail = styled(Body)`
  &:first-child {
    margin-bottom: 8px;
  }

  a {
    ${bodyCopy};
    text-decoration: none;

    &:hover {
      color: ${colors.highlight};
      cursor: pointer;
    }
  }
`;

export const ButtonGroup = styled.div`
  button {
    margin-right: 24px;
    transition: all 0.3s ${customBezierEase};

    &:last-child {
      margin-right: 0;
    }

    &.hidden {
      display: none;
    }
  }
`;
