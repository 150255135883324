import Button from 'components/Button';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { ButtonGroup, Container, Detail, Details, Title, Type } from './styles';
import { IconEye, IconEyeSlash } from 'components/IconsView';

interface Props {
  label: string;
  updatedAt?: string;
  submitAllForms: () => void;
  isDirtyForm?: boolean;
  mileageRateIsDirty?: boolean;
  hideManage?: boolean;
  visible?: boolean;
  parentVisible?: boolean;
  setVisible?: (visible: boolean) => void;
  showVisible: boolean;
  slug: string;
}

const Header = ({
  label,
  updatedAt,
  submitAllForms,
  visible,
  setVisible,
  hideManage,
  isDirtyForm = false,
  mileageRateIsDirty = false,
  showVisible,
  slug,
  parentVisible = true,
}: Props): JSX.Element => {
  const [link, setLink] = useState('');

  const handleVisible = (): void => {
    if (setVisible) {
      setVisible(!visible);
    }
  };

  useEffect(() => {
    const location = window.location.origin;
    if (location === 'https://qa.admin.intranet.fiveanddone.com') {
      setLink(
        `https://qa.intranet.fiveanddone.com${slug === '//' ? '' : slug}`
      );
    } else if (location === 'https://admin.intranet.fiveanddone.com') {
      setLink(`https://intranet.fiveanddone.com${slug === '//' ? '' : slug}`);
    } else if ('http://localhost:3000') {
      setLink(`http://localhost:3001${slug === '//' ? '' : slug}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);

  return (
    <Container>
      <div>
        {!hideManage && <Type>Manage</Type>}
        <Title>{label}</Title>
      </div>
      <div>
        <Details>
          <Detail>
            Status: {isDirtyForm || mileageRateIsDirty ? 'Draft' : 'Published'}{' '}
            {visible && slug ? (
              <>
                (
                <a href={link} target="_blank">
                  View
                </a>
                )
              </>
            ) : (
              ''
            )}
          </Detail>
          <Detail>
            Last saved on{' '}
            {updatedAt ? dayjs(updatedAt).format('MMM D, YYYY') : '---'}
          </Detail>
        </Details>
        <ButtonGroup>
          {showVisible && (
            <Button
              onClick={handleVisible}
              Icon={visible ? IconEye : IconEyeSlash}
              type="button"
              // {...(visible ? null : { secondary: true })}
              secondary
              disabled={!parentVisible}
            >
              {visible ? 'Visible' : 'Hidden'}
            </Button>
          )}
          <Button onClick={submitAllForms}>Save Changes</Button>
        </ButtonGroup>
      </div>
    </Container>
  );
};

export default Header;

// set default prop type for label
Header.defaultProps = {
  label: '',
};
