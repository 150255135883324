/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getEvents = /* GraphQL */ `
  query GetEvents($input: GetEventsInput) {
    getEvents(input: $input) {
      events {
        startDate
        endDate
        title
        id
      }
    }
  }
`;
export const getApplication = /* GraphQL */ `
  query GetApplication($id: ID!) {
    getApplication(id: $id) {
      id
      name
      image
      username
      password
      serial
      path
      website
      sort
      type
      createdAt
      updatedAt
    }
  }
`;
export const listApplications = /* GraphQL */ `
  query ListApplications(
    $filter: ModelApplicationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listApplications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        image
        username
        password
        serial
        path
        website
        sort
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFAQ = /* GraphQL */ `
  query GetFAQ($id: ID!) {
    getFAQ(id: $id) {
      id
      question
      answer
      sectionID
      sort
      officeIDs
      createdAt
      updatedAt
    }
  }
`;
export const listFAQS = /* GraphQL */ `
  query ListFAQS(
    $filter: ModelFAQFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFAQS(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        question
        answer
        sectionID
        sort
        officeIDs
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFAQsection = /* GraphQL */ `
  query GetFAQsection($id: ID!) {
    getFAQsection(id: $id) {
      id
      title
      sort
      faqs {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listFAQsections = /* GraphQL */ `
  query ListFAQsections(
    $filter: ModelFAQsectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFAQsections(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        sort
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFreelancer = /* GraphQL */ `
  query GetFreelancer($id: ID!) {
    getFreelancer(id: $id) {
      id
      name
      email
      phone
      location
      portfolio
      design
      ux
      motion
      development
      photography
      video
      copywriting
      branding
      rate
      referredBy
      notes
      status
      createdAt
      updatedAt
    }
  }
`;
export const listFreelancers = /* GraphQL */ `
  query ListFreelancers(
    $filter: ModelFreelancerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFreelancers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        email
        phone
        location
        portfolio
        design
        ux
        motion
        development
        photography
        video
        copywriting
        branding
        rate
        referredBy
        notes
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGIF = /* GraphQL */ `
  query GetGIF($id: ID!) {
    getGIF(id: $id) {
      id
      sort
      title
      image
      active
      createdAt
      updatedAt
    }
  }
`;
export const listGIFS = /* GraphQL */ `
  query ListGIFS(
    $filter: ModelGIFFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGIFS(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sort
        title
        image
        active
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getHoliday = /* GraphQL */ `
  query GetHoliday($id: ID!) {
    getHoliday(id: $id) {
      id
      title
      sort
      disclaimer
      startDate
      endDate
      createdAt
      updatedAt
      yearID
    }
  }
`;
export const listHolidays = /* GraphQL */ `
  query ListHolidays(
    $filter: ModelHolidayFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHolidays(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        sort
        disclaimer
        startDate
        endDate
        createdAt
        updatedAt
        yearID
      }
      nextToken
    }
  }
`;
export const getMember = /* GraphQL */ `
  query GetMember($id: ID!) {
    getMember(id: $id) {
      id
      title
      image
      name
      nameLow
      description
      department
      reportTo
      projects
      phone
      email
      active
      startDate
      officeID
      office {
        id
        name
        sort
        active
        group
        timezone
        createdAt
        updatedAt
      }
      sort
      createdAt
      updatedAt
    }
  }
`;
export const listMembers = /* GraphQL */ `
  query ListMembers(
    $filter: ModelMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMembers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        image
        name
        nameLow
        description
        department
        reportTo
        projects
        phone
        email
        active
        startDate
        officeID
        sort
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMileageRate = /* GraphQL */ `
  query GetMileageRate($id: ID!) {
    getMileageRate(id: $id) {
      id
      rate
      date
      createdAt
      updatedAt
    }
  }
`;
export const listMileageRates = /* GraphQL */ `
  query ListMileageRates(
    $filter: ModelMileageRateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMileageRates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        rate
        date
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOffice = /* GraphQL */ `
  query GetOffice($id: ID!) {
    getOffice(id: $id) {
      id
      name
      sort
      active
      group
      timezone
      members {
        nextToken
      }
      pages {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listOffices = /* GraphQL */ `
  query ListOffices(
    $filter: ModelOfficeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOffices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        sort
        active
        group
        timezone
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLocation = /* GraphQL */ `
  query GetLocation($id: ID!) {
    getLocation(id: $id) {
      id
      type
      sort
      active
      officeID
      office {
        id
        name
        sort
        active
        group
        timezone
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listLocations = /* GraphQL */ `
  query ListLocations(
    $filter: ModelLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLocations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        sort
        active
        officeID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPage = /* GraphQL */ `
  query GetPage($id: ID!) {
    getPage(id: $id) {
      id
      label
      status
      createdAt
      updatedAt
      title
      slug
      description
      eyebrow
      headline
      body
      inlineBody
      ctaText
      ctaLink
      headlineLow
      bodyLow
      layout
      quicklinks {
        nextToken
      }
      referencedQuicklinks {
        nextToken
      }
      pageId
      pages {
        nextToken
      }
      parentPage {
        id
        label
        status
        createdAt
        updatedAt
        title
        slug
        description
        eyebrow
        headline
        body
        inlineBody
        ctaText
        ctaLink
        headlineLow
        bodyLow
        layout
        pageId
        local
        publishedAt
        image
        sort
        meta
        officeId
      }
      local
      publishedAt
      image
      sort
      meta
      officeId
      office {
        id
        name
        sort
        active
        group
        timezone
        createdAt
        updatedAt
      }
    }
  }
`;
export const listPages = /* GraphQL */ `
  query ListPages(
    $filter: ModelPageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        label
        status
        createdAt
        updatedAt
        title
        slug
        description
        eyebrow
        headline
        body
        inlineBody
        ctaText
        ctaLink
        headlineLow
        bodyLow
        layout
        pageId
        local
        publishedAt
        image
        sort
        meta
        officeId
      }
      nextToken
    }
  }
`;
export const getPolicy = /* GraphQL */ `
  query GetPolicy($id: ID!) {
    getPolicy(id: $id) {
      id
      location
      title
      acknoledgement
      version
      content
      createdAt
      updatedAt
    }
  }
`;
export const listPolicies = /* GraphQL */ `
  query ListPolicies(
    $filter: ModelPolicyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPolicies(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        location
        title
        acknoledgement
        version
        content
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getQuickLink = /* GraphQL */ `
  query GetQuickLink($id: ID!) {
    getQuickLink(id: $id) {
      id
      pageId
      page {
        id
        label
        status
        createdAt
        updatedAt
        title
        slug
        description
        eyebrow
        headline
        body
        inlineBody
        ctaText
        ctaLink
        headlineLow
        bodyLow
        layout
        pageId
        local
        publishedAt
        image
        sort
        meta
        officeId
      }
      sort
      title
      body
      linkTitle
      externalLink
      linkId
      link {
        id
        label
        status
        createdAt
        updatedAt
        title
        slug
        description
        eyebrow
        headline
        body
        inlineBody
        ctaText
        ctaLink
        headlineLow
        bodyLow
        layout
        pageId
        local
        publishedAt
        image
        sort
        meta
        officeId
      }
      createdAt
      updatedAt
    }
  }
`;
export const listQuickLinks = /* GraphQL */ `
  query ListQuickLinks(
    $filter: ModelQuickLinkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuickLinks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        pageId
        sort
        title
        body
        linkTitle
        externalLink
        linkId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getYear = /* GraphQL */ `
  query GetYear($id: ID!) {
    getYear(id: $id) {
      id
      title
      sort
      date
      createdAt
      updatedAt
      holidays {
        nextToken
      }
    }
  }
`;
export const listYears = /* GraphQL */ `
  query ListYears(
    $filter: ModelYearFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listYears(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        sort
        date
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listApplicationByType = /* GraphQL */ `
  query ListApplicationByType(
    $type: String
    $sort: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelApplicationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listApplicationByType(
      type: $type
      sort: $sort
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        image
        username
        password
        serial
        path
        website
        sort
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listFAQBySection = /* GraphQL */ `
  query ListFAQBySection(
    $sectionID: ID
    $sort: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFAQFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFAQBySection(
      sectionID: $sectionID
      sort: $sort
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        question
        answer
        sectionID
        sort
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const faqSectionByTitle = /* GraphQL */ `
  query FaqSectionByTitle(
    $title: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFAQsectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    faqSectionByTitle(
      title: $title
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        sort
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listMembersByOffice = /* GraphQL */ `
  query ListMembersByOffice(
    $officeID: ID
    $sort: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMembersByOffice(
      officeID: $officeID
      sort: $sort
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        image
        name
        nameLow
        description
        department
        reportTo
        projects
        phone
        email
        active
        startDate
        officeID
        sort
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listLocationByType = /* GraphQL */ `
  query ListLocationByType(
    $type: String
    $sort: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLocationByType(
      type: $type
      sort: $sort
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        sort
        active
        officeID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listLocationByOffice = /* GraphQL */ `
  query ListLocationByOffice(
    $officeID: ID
    $sort: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLocationByOffice(
      officeID: $officeID
      sort: $sort
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        sort
        active
        officeID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listPagesByPageId = /* GraphQL */ `
  query ListPagesByPageId(
    $pageId: ID
    $sort: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPagesByPageId(
      pageId: $pageId
      sort: $sort
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        label
        status
        createdAt
        updatedAt
        title
        slug
        description
        eyebrow
        headline
        body
        inlineBody
        ctaText
        ctaLink
        headlineLow
        bodyLow
        layout
        pageId
        local
        publishedAt
        image
        sort
        meta
        officeId
      }
      nextToken
    }
  }
`;
export const getPageBySlug = /* GraphQL */ `
  query GetPageBySlug(
    $slug: String
    $sortDirection: ModelSortDirection
    $filter: ModelPageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPageBySlug(
      slug: $slug
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        label
        status
        createdAt
        updatedAt
        title
        slug
        description
        eyebrow
        headline
        body
        inlineBody
        ctaText
        ctaLink
        headlineLow
        bodyLow
        layout
        pageId
        local
        publishedAt
        image
        sort
        meta
        officeId
      }
      nextToken
    }
  }
`;
export const listPagesByStatusAndHeadline = /* GraphQL */ `
  query ListPagesByStatusAndHeadline(
    $status: PageStatus
    $headline: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPagesByStatusAndHeadline(
      status: $status
      headline: $headline
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        label
        status
        createdAt
        updatedAt
        title
        slug
        description
        eyebrow
        headline
        body
        inlineBody
        ctaText
        ctaLink
        headlineLow
        bodyLow
        layout
        pageId
        local
        publishedAt
        image
        sort
        meta
        officeId
      }
      nextToken
    }
  }
`;
export const listPagesByOffice = /* GraphQL */ `
  query ListPagesByOffice(
    $officeId: ID
    $sort: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPagesByOffice(
      officeId: $officeId
      sort: $sort
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        label
        status
        createdAt
        updatedAt
        title
        slug
        description
        eyebrow
        headline
        body
        inlineBody
        ctaText
        ctaLink
        headlineLow
        bodyLow
        layout
        pageId
        local
        publishedAt
        image
        sort
        meta
        officeId
      }
      nextToken
    }
  }
`;
export const listPagesByLayout = /* GraphQL */ `
  query ListPagesByLayout(
    $layout: PageLayout
    $sortDirection: ModelSortDirection
    $filter: ModelPageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPagesByLayout(
      layout: $layout
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        label
        status
        createdAt
        updatedAt
        title
        slug
        description
        eyebrow
        headline
        body
        inlineBody
        ctaText
        ctaLink
        headlineLow
        bodyLow
        layout
        pageId
        local
        publishedAt
        image
        sort
        meta
        officeId
      }
      nextToken
    }
  }
`;
export const listQuicklinksByPageId = /* GraphQL */ `
  query ListQuicklinksByPageId(
    $pageId: ID
    $sort: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuickLinkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuicklinksByPageId(
      pageId: $pageId
      sort: $sort
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        pageId
        sort
        title
        body
        linkTitle
        externalLink
        linkId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listQuicklinksByLinkId = /* GraphQL */ `
  query ListQuicklinksByLinkId(
    $linkId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelQuickLinkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuicklinksByLinkId(
      linkId: $linkId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        pageId
        sort
        title
        body
        linkTitle
        externalLink
        linkId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
