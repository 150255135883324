/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type EmailPTOInput = {
  name: string,
  email: string,
  subject: string,
  message?: string | null,
  notes?: string | null,
  startDate: string,
  endDate: string,
  daysOff?: number | null,
  startTime?: string | null,
  endTime?: string | null,
  type: PTOType,
  timezone: string,
  partialDay?: boolean | null,
};

export enum PTOType {
  pto = "pto",
  wfh = "wfh",
}


export type EmailPTOOutput = {
  __typename: "EmailPTOOutput",
  message?: string | null,
};

export type EmailExpenseReportInput = {
  name: string,
  email: string,
  subject: string,
  notes?: string | null,
  total: number,
  items: Array< ExpenseReportItemInput >,
  receipts: Array< ExpenseReportReceiptInput >,
};

export type ExpenseReportItemInput = {
  date?: string | null,
  item?: string | null,
  description?: string | null,
  project?: string | null,
  category?: string | null,
  quantity?: string | null,
  amount?: string | null,
  subtotal?: string | null,
};

export type ExpenseReportReceiptInput = {
  name?: string | null,
  key?: string | null,
};

export type UserLogoutInput = {
  uri?: string | null,
};

export type SortLocationsInput = {
  oldIndex?: number | null,
  newIndex?: number | null,
  items?: Array< SortItemInput | null > | null,
};

export type SortItemInput = {
  id?: string | null,
  sort?: number | null,
};

export type SortLocationsOutput = {
  __typename: "SortLocationsOutput",
  items?: Array< string | null > | null,
};

export type SortItemsInput = {
  tableName: string,
  oldIndex?: number | null,
  newIndex?: number | null,
  items?: Array< SortItemInput | null > | null,
};

export type SortItemsOutput = {
  __typename: "SortItemsOutput",
  items?: Array< string | null > | null,
};

export type SortItemsV2Input = {
  tableName: string,
  items?: Array< SortItemInput | null > | null,
};

export type ViewItemsInput = {
  tableName: string,
  status?: string | null,
  items?: Array< ViewItemInput | null > | null,
};

export type ViewItemInput = {
  id?: string | null,
  status?: string | null,
  active?: boolean | null,
};

export type ToggleOfficeInput = {
  id: string,
  active: boolean,
};

export type ToggleOfficeOutput = {
  __typename: "ToggleOfficeOutput",
  id?: string | null,
};

export type CreateOfficeTemplatesInput = {
  pageId: string,
};

export type CreateOfficeTemplatesOutput = {
  __typename: "CreateOfficeTemplatesOutput",
  ids?: Array< string | null > | null,
};

export type CheckOfficesToCreateTemplatesInput = {
  id: string,
};

export type CheckOfficesToCreateTemplatesOutput = {
  __typename: "CheckOfficesToCreateTemplatesOutput",
  shouldRefetch?: boolean | null,
};

export type UpdateOfficeAndConnectedPagesInput = {
  items?: Array< UpdateOffices | null > | null,
};

export type UpdateOffices = {
  id: string,
  active: boolean,
};

export type UpdateOfficeAndConnectedPagesOutput = {
  __typename: "UpdateOfficeAndConnectedPagesOutput",
  ids?: Array< string | null > | null,
};

export type CreateApplicationInput = {
  id?: string | null,
  name?: string | null,
  image?: string | null,
  username?: string | null,
  password?: string | null,
  serial?: string | null,
  path?: string | null,
  website?: string | null,
  sort?: number | null,
  type: string,
};

export type ModelApplicationConditionInput = {
  name?: ModelStringInput | null,
  image?: ModelStringInput | null,
  username?: ModelStringInput | null,
  password?: ModelStringInput | null,
  serial?: ModelStringInput | null,
  path?: ModelStringInput | null,
  website?: ModelStringInput | null,
  sort?: ModelIntInput | null,
  type?: ModelStringInput | null,
  and?: Array< ModelApplicationConditionInput | null > | null,
  or?: Array< ModelApplicationConditionInput | null > | null,
  not?: ModelApplicationConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type Application = {
  __typename: "Application",
  id: string,
  name?: string | null,
  image?: string | null,
  username?: string | null,
  password?: string | null,
  serial?: string | null,
  path?: string | null,
  website?: string | null,
  sort?: number | null,
  type: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateApplicationInput = {
  id: string,
  name?: string | null,
  image?: string | null,
  username?: string | null,
  password?: string | null,
  serial?: string | null,
  path?: string | null,
  website?: string | null,
  sort?: number | null,
  type?: string | null,
};

export type DeleteApplicationInput = {
  id: string,
};

export type CreateFAQInput = {
  id?: string | null,
  question?: string | null,
  answer?: string | null,
  sectionID: string,
  sort?: number | null,
  officeIDs?: Array< string > | null,
};

export type ModelFAQConditionInput = {
  question?: ModelStringInput | null,
  answer?: ModelStringInput | null,
  sectionID?: ModelIDInput | null,
  sort?: ModelIntInput | null,
  officeIDs?: ModelIDInput | null,
  and?: Array< ModelFAQConditionInput | null > | null,
  or?: Array< ModelFAQConditionInput | null > | null,
  not?: ModelFAQConditionInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type FAQ = {
  __typename: "FAQ",
  id: string,
  question?: string | null,
  answer?: string | null,
  sectionID: string,
  sort?: number | null,
  officeIDs?: Array< string > | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateFAQInput = {
  id: string,
  question?: string | null,
  answer?: string | null,
  sectionID?: string | null,
  sort?: number | null,
  officeIDs?: Array< string > | null,
};

export type DeleteFAQInput = {
  id: string,
};

export type CreateFAQsectionInput = {
  id?: string | null,
  title?: string | null,
  sort?: number | null,
  createdAt?: string | null,
};

export type ModelFAQsectionConditionInput = {
  title?: ModelStringInput | null,
  sort?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelFAQsectionConditionInput | null > | null,
  or?: Array< ModelFAQsectionConditionInput | null > | null,
  not?: ModelFAQsectionConditionInput | null,
};

export type FAQsection = {
  __typename: "FAQsection",
  id: string,
  title?: string | null,
  sort?: number | null,
  faqs?: ModelFAQConnection | null,
  createdAt?: string | null,
  updatedAt: string,
};

export type ModelFAQConnection = {
  __typename: "ModelFAQConnection",
  items:  Array<FAQ >,
  nextToken?: string | null,
};

export type UpdateFAQsectionInput = {
  id: string,
  title?: string | null,
  sort?: number | null,
  createdAt?: string | null,
};

export type DeleteFAQsectionInput = {
  id: string,
};

export type CreateFreelancerInput = {
  id?: string | null,
  name?: string | null,
  email?: string | null,
  phone?: string | null,
  location?: string | null,
  portfolio?: string | null,
  design?: boolean | null,
  ux?: boolean | null,
  motion?: boolean | null,
  development?: boolean | null,
  photography?: boolean | null,
  video?: boolean | null,
  copywriting?: boolean | null,
  branding?: boolean | null,
  rate?: string | null,
  referredBy?: string | null,
  notes?: string | null,
  status?: string | null,
};

export type ModelFreelancerConditionInput = {
  name?: ModelStringInput | null,
  email?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  location?: ModelStringInput | null,
  portfolio?: ModelStringInput | null,
  design?: ModelBooleanInput | null,
  ux?: ModelBooleanInput | null,
  motion?: ModelBooleanInput | null,
  development?: ModelBooleanInput | null,
  photography?: ModelBooleanInput | null,
  video?: ModelBooleanInput | null,
  copywriting?: ModelBooleanInput | null,
  branding?: ModelBooleanInput | null,
  rate?: ModelStringInput | null,
  referredBy?: ModelStringInput | null,
  notes?: ModelStringInput | null,
  status?: ModelStringInput | null,
  and?: Array< ModelFreelancerConditionInput | null > | null,
  or?: Array< ModelFreelancerConditionInput | null > | null,
  not?: ModelFreelancerConditionInput | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type Freelancer = {
  __typename: "Freelancer",
  id: string,
  name?: string | null,
  email?: string | null,
  phone?: string | null,
  location?: string | null,
  portfolio?: string | null,
  design?: boolean | null,
  ux?: boolean | null,
  motion?: boolean | null,
  development?: boolean | null,
  photography?: boolean | null,
  video?: boolean | null,
  copywriting?: boolean | null,
  branding?: boolean | null,
  rate?: string | null,
  referredBy?: string | null,
  notes?: string | null,
  status?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateFreelancerInput = {
  id: string,
  name?: string | null,
  email?: string | null,
  phone?: string | null,
  location?: string | null,
  portfolio?: string | null,
  design?: boolean | null,
  ux?: boolean | null,
  motion?: boolean | null,
  development?: boolean | null,
  photography?: boolean | null,
  video?: boolean | null,
  copywriting?: boolean | null,
  branding?: boolean | null,
  rate?: string | null,
  referredBy?: string | null,
  notes?: string | null,
  status?: string | null,
};

export type DeleteFreelancerInput = {
  id: string,
};

export type CreateGIFInput = {
  id?: string | null,
  sort?: number | null,
  title?: string | null,
  image?: string | null,
  active?: boolean | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelGIFConditionInput = {
  sort?: ModelIntInput | null,
  title?: ModelStringInput | null,
  image?: ModelStringInput | null,
  active?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelGIFConditionInput | null > | null,
  or?: Array< ModelGIFConditionInput | null > | null,
  not?: ModelGIFConditionInput | null,
};

export type GIF = {
  __typename: "GIF",
  id: string,
  sort?: number | null,
  title?: string | null,
  image?: string | null,
  active?: boolean | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type UpdateGIFInput = {
  id: string,
  sort?: number | null,
  title?: string | null,
  image?: string | null,
  active?: boolean | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteGIFInput = {
  id: string,
};

export type CreateHolidayInput = {
  id?: string | null,
  title?: string | null,
  sort?: number | null,
  disclaimer?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  yearID: string,
};

export type ModelHolidayConditionInput = {
  title?: ModelStringInput | null,
  sort?: ModelIntInput | null,
  disclaimer?: ModelStringInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  yearID?: ModelIDInput | null,
  and?: Array< ModelHolidayConditionInput | null > | null,
  or?: Array< ModelHolidayConditionInput | null > | null,
  not?: ModelHolidayConditionInput | null,
};

export type Holiday = {
  __typename: "Holiday",
  id: string,
  title?: string | null,
  sort?: number | null,
  disclaimer?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  yearID: string,
};

export type UpdateHolidayInput = {
  id: string,
  title?: string | null,
  sort?: number | null,
  disclaimer?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  yearID?: string | null,
};

export type DeleteHolidayInput = {
  id: string,
};

export type CreateMemberInput = {
  id?: string | null,
  title?: string | null,
  image?: string | null,
  name: string,
  nameLow?: string | null,
  description?: string | null,
  department?: string | null,
  reportTo?: string | null,
  projects?: Array< string | null > | null,
  phone?: string | null,
  email?: string | null,
  active?: boolean | null,
  startDate?: string | null,
  officeID: string,
  sort?: number | null,
};

export type ModelMemberConditionInput = {
  title?: ModelStringInput | null,
  image?: ModelStringInput | null,
  name?: ModelStringInput | null,
  nameLow?: ModelStringInput | null,
  description?: ModelStringInput | null,
  department?: ModelStringInput | null,
  reportTo?: ModelStringInput | null,
  projects?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  email?: ModelStringInput | null,
  active?: ModelBooleanInput | null,
  startDate?: ModelStringInput | null,
  officeID?: ModelIDInput | null,
  sort?: ModelIntInput | null,
  and?: Array< ModelMemberConditionInput | null > | null,
  or?: Array< ModelMemberConditionInput | null > | null,
  not?: ModelMemberConditionInput | null,
};

export type Member = {
  __typename: "Member",
  id: string,
  title?: string | null,
  image?: string | null,
  name: string,
  nameLow?: string | null,
  description?: string | null,
  department?: string | null,
  reportTo?: string | null,
  projects?: Array< string | null > | null,
  phone?: string | null,
  email?: string | null,
  active?: boolean | null,
  startDate?: string | null,
  officeID: string,
  office?: Office | null,
  sort?: number | null,
  createdAt: string,
  updatedAt: string,
};

export type Office = {
  __typename: "Office",
  id: string,
  name?: string | null,
  sort?: number | null,
  active?: boolean | null,
  group?: string | null,
  timezone?: string | null,
  members?: ModelMemberConnection | null,
  pages?: ModelPageConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelMemberConnection = {
  __typename: "ModelMemberConnection",
  items:  Array<Member >,
  nextToken?: string | null,
};

export type ModelPageConnection = {
  __typename: "ModelPageConnection",
  items:  Array<Page >,
  nextToken?: string | null,
};

export type Page = {
  __typename: "Page",
  id: string,
  label?: string | null,
  status?: PageStatus | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  title: string,
  slug: string,
  description?: string | null,
  eyebrow?: string | null,
  headline?: string | null,
  body?: string | null,
  inlineBody?: string | null,
  ctaText?: string | null,
  ctaLink?: string | null,
  headlineLow?: string | null,
  bodyLow?: string | null,
  layout?: PageLayout | null,
  quicklinks?: ModelQuickLinkConnection | null,
  referencedQuicklinks?: ModelQuickLinkConnection | null,
  pageId: string,
  pages?: ModelPageConnection | null,
  parentPage?: Page | null,
  local?: string | null,
  publishedAt?: string | null,
  image?: string | null,
  sort?: number | null,
  meta?: string | null,
  officeId?: string | null,
  office?: Office | null,
};

export enum PageStatus {
  active = "active",
  pending = "pending",
  disabled = "disabled",
}


export enum PageLayout {
  plain = "plain",
  tabbed = "tabbed",
  template = "template",
  home = "home",
  main = "main",
  applications = "applications",
  team = "team",
  holidays = "holidays",
  freelance = "freelance",
  faq = "faq",
  pto = "pto",
  expenses = "expenses",
  email = "email",
  error = "error",
  lunch = "lunch",
  officeTab = "officeTab",
}


export type ModelQuickLinkConnection = {
  __typename: "ModelQuickLinkConnection",
  items:  Array<QuickLink >,
  nextToken?: string | null,
};

export type QuickLink = {
  __typename: "QuickLink",
  id: string,
  pageId: string,
  page?: Page | null,
  sort?: number | null,
  title?: string | null,
  body?: string | null,
  linkTitle?: string | null,
  externalLink?: string | null,
  linkId?: string | null,
  link?: Page | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateMemberInput = {
  id: string,
  title?: string | null,
  image?: string | null,
  name?: string | null,
  nameLow?: string | null,
  description?: string | null,
  department?: string | null,
  reportTo?: string | null,
  projects?: Array< string | null > | null,
  phone?: string | null,
  email?: string | null,
  active?: boolean | null,
  startDate?: string | null,
  officeID?: string | null,
  sort?: number | null,
};

export type DeleteMemberInput = {
  id: string,
};

export type CreateMileageRateInput = {
  id?: string | null,
  rate?: number | null,
  date?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelMileageRateConditionInput = {
  rate?: ModelFloatInput | null,
  date?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelMileageRateConditionInput | null > | null,
  or?: Array< ModelMileageRateConditionInput | null > | null,
  not?: ModelMileageRateConditionInput | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type MileageRate = {
  __typename: "MileageRate",
  id: string,
  rate?: number | null,
  date?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type UpdateMileageRateInput = {
  id: string,
  rate?: number | null,
  date?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteMileageRateInput = {
  id: string,
};

export type CreateOfficeInput = {
  id?: string | null,
  name?: string | null,
  sort?: number | null,
  active?: boolean | null,
  group?: string | null,
  timezone?: string | null,
};

export type ModelOfficeConditionInput = {
  name?: ModelStringInput | null,
  sort?: ModelIntInput | null,
  active?: ModelBooleanInput | null,
  group?: ModelStringInput | null,
  timezone?: ModelStringInput | null,
  and?: Array< ModelOfficeConditionInput | null > | null,
  or?: Array< ModelOfficeConditionInput | null > | null,
  not?: ModelOfficeConditionInput | null,
};

export type UpdateOfficeInput = {
  id: string,
  name?: string | null,
  sort?: number | null,
  active?: boolean | null,
  group?: string | null,
  timezone?: string | null,
};

export type DeleteOfficeInput = {
  id: string,
};

export type CreateLocationInput = {
  id?: string | null,
  type: string,
  sort?: number | null,
  active?: boolean | null,
  officeID: string,
};

export type ModelLocationConditionInput = {
  type?: ModelStringInput | null,
  sort?: ModelIntInput | null,
  active?: ModelBooleanInput | null,
  officeID?: ModelIDInput | null,
  and?: Array< ModelLocationConditionInput | null > | null,
  or?: Array< ModelLocationConditionInput | null > | null,
  not?: ModelLocationConditionInput | null,
};

export type Location = {
  __typename: "Location",
  id: string,
  type: string,
  sort?: number | null,
  active?: boolean | null,
  officeID: string,
  office: Office,
  createdAt: string,
  updatedAt: string,
};

export type UpdateLocationInput = {
  id: string,
  type?: string | null,
  sort?: number | null,
  active?: boolean | null,
  officeID?: string | null,
};

export type DeleteLocationInput = {
  id: string,
};

export type CreatePageInput = {
  id?: string | null,
  label?: string | null,
  status?: PageStatus | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  title: string,
  slug: string,
  description?: string | null,
  eyebrow?: string | null,
  headline?: string | null,
  body?: string | null,
  inlineBody?: string | null,
  ctaText?: string | null,
  ctaLink?: string | null,
  headlineLow?: string | null,
  bodyLow?: string | null,
  layout?: PageLayout | null,
  pageId: string,
  local?: string | null,
  publishedAt?: string | null,
  image?: string | null,
  sort?: number | null,
  meta?: string | null,
  officeId?: string | null,
};

export type ModelPageConditionInput = {
  label?: ModelStringInput | null,
  status?: ModelPageStatusInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  title?: ModelStringInput | null,
  slug?: ModelStringInput | null,
  description?: ModelStringInput | null,
  eyebrow?: ModelStringInput | null,
  headline?: ModelStringInput | null,
  body?: ModelStringInput | null,
  inlineBody?: ModelStringInput | null,
  ctaText?: ModelStringInput | null,
  ctaLink?: ModelStringInput | null,
  headlineLow?: ModelStringInput | null,
  bodyLow?: ModelStringInput | null,
  layout?: ModelPageLayoutInput | null,
  pageId?: ModelIDInput | null,
  local?: ModelStringInput | null,
  publishedAt?: ModelStringInput | null,
  image?: ModelStringInput | null,
  sort?: ModelIntInput | null,
  meta?: ModelStringInput | null,
  officeId?: ModelIDInput | null,
  and?: Array< ModelPageConditionInput | null > | null,
  or?: Array< ModelPageConditionInput | null > | null,
  not?: ModelPageConditionInput | null,
};

export type ModelPageStatusInput = {
  eq?: PageStatus | null,
  ne?: PageStatus | null,
};

export type ModelPageLayoutInput = {
  eq?: PageLayout | null,
  ne?: PageLayout | null,
};

export type UpdatePageInput = {
  id: string,
  label?: string | null,
  status?: PageStatus | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  title?: string | null,
  slug?: string | null,
  description?: string | null,
  eyebrow?: string | null,
  headline?: string | null,
  body?: string | null,
  inlineBody?: string | null,
  ctaText?: string | null,
  ctaLink?: string | null,
  headlineLow?: string | null,
  bodyLow?: string | null,
  layout?: PageLayout | null,
  pageId?: string | null,
  local?: string | null,
  publishedAt?: string | null,
  image?: string | null,
  sort?: number | null,
  meta?: string | null,
  officeId?: string | null,
};

export type DeletePageInput = {
  id: string,
};

export type CreatePolicyInput = {
  id?: string | null,
  location?: AllLocation | null,
  title?: string | null,
  acknoledgement?: boolean | null,
  version?: string | null,
  content?: string | null,
};

export enum AllLocation {
  dallas = "dallas",
  oc = "oc",
  both = "both",
}


export type ModelPolicyConditionInput = {
  location?: ModelAllLocationInput | null,
  title?: ModelStringInput | null,
  acknoledgement?: ModelBooleanInput | null,
  version?: ModelStringInput | null,
  content?: ModelStringInput | null,
  and?: Array< ModelPolicyConditionInput | null > | null,
  or?: Array< ModelPolicyConditionInput | null > | null,
  not?: ModelPolicyConditionInput | null,
};

export type ModelAllLocationInput = {
  eq?: AllLocation | null,
  ne?: AllLocation | null,
};

export type Policy = {
  __typename: "Policy",
  id: string,
  location?: AllLocation | null,
  title?: string | null,
  acknoledgement?: boolean | null,
  version?: string | null,
  content?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdatePolicyInput = {
  id: string,
  location?: AllLocation | null,
  title?: string | null,
  acknoledgement?: boolean | null,
  version?: string | null,
  content?: string | null,
};

export type DeletePolicyInput = {
  id: string,
};

export type CreateQuickLinkInput = {
  id?: string | null,
  pageId: string,
  sort?: number | null,
  title?: string | null,
  body?: string | null,
  linkTitle?: string | null,
  externalLink?: string | null,
  linkId?: string | null,
};

export type ModelQuickLinkConditionInput = {
  pageId?: ModelIDInput | null,
  sort?: ModelIntInput | null,
  title?: ModelStringInput | null,
  body?: ModelStringInput | null,
  linkTitle?: ModelStringInput | null,
  externalLink?: ModelStringInput | null,
  linkId?: ModelIDInput | null,
  and?: Array< ModelQuickLinkConditionInput | null > | null,
  or?: Array< ModelQuickLinkConditionInput | null > | null,
  not?: ModelQuickLinkConditionInput | null,
};

export type UpdateQuickLinkInput = {
  id: string,
  pageId?: string | null,
  sort?: number | null,
  title?: string | null,
  body?: string | null,
  linkTitle?: string | null,
  externalLink?: string | null,
  linkId?: string | null,
};

export type DeleteQuickLinkInput = {
  id: string,
};

export type CreateYearInput = {
  id?: string | null,
  title?: string | null,
  sort?: number | null,
  date?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelYearConditionInput = {
  title?: ModelStringInput | null,
  sort?: ModelIntInput | null,
  date?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelYearConditionInput | null > | null,
  or?: Array< ModelYearConditionInput | null > | null,
  not?: ModelYearConditionInput | null,
};

export type Year = {
  __typename: "Year",
  id: string,
  title?: string | null,
  sort?: number | null,
  date?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  holidays?: ModelHolidayConnection | null,
};

export type ModelHolidayConnection = {
  __typename: "ModelHolidayConnection",
  items:  Array<Holiday >,
  nextToken?: string | null,
};

export type UpdateYearInput = {
  id: string,
  title?: string | null,
  sort?: number | null,
  date?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteYearInput = {
  id: string,
};

export type GetEventsInput = {
  calID?: string | null,
  filterPhrases?: Array< string | null > | null,
  accessToken?: string | null,
};

export type GetEventOutput = {
  __typename: "GetEventOutput",
  events?:  Array<EventsOutput | null > | null,
};

export type EventsOutput = {
  __typename: "EventsOutput",
  startDate?: string | null,
  endDate?: string | null,
  title?: string | null,
  id?: string | null,
};

export type ModelApplicationFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  image?: ModelStringInput | null,
  username?: ModelStringInput | null,
  password?: ModelStringInput | null,
  serial?: ModelStringInput | null,
  path?: ModelStringInput | null,
  website?: ModelStringInput | null,
  sort?: ModelIntInput | null,
  type?: ModelStringInput | null,
  and?: Array< ModelApplicationFilterInput | null > | null,
  or?: Array< ModelApplicationFilterInput | null > | null,
  not?: ModelApplicationFilterInput | null,
};

export type ModelApplicationConnection = {
  __typename: "ModelApplicationConnection",
  items:  Array<Application >,
  nextToken?: string | null,
};

export type ModelFAQFilterInput = {
  id?: ModelIDInput | null,
  question?: ModelStringInput | null,
  answer?: ModelStringInput | null,
  sectionID?: ModelIDInput | null,
  sort?: ModelIntInput | null,
  officeIDs?: ModelIDInput | null,
  and?: Array< ModelFAQFilterInput | null > | null,
  or?: Array< ModelFAQFilterInput | null > | null,
  not?: ModelFAQFilterInput | null,
};

export type ModelFAQsectionFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  sort?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelFAQsectionFilterInput | null > | null,
  or?: Array< ModelFAQsectionFilterInput | null > | null,
  not?: ModelFAQsectionFilterInput | null,
};

export type ModelFAQsectionConnection = {
  __typename: "ModelFAQsectionConnection",
  items:  Array<FAQsection >,
  nextToken?: string | null,
};

export type ModelFreelancerFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  email?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  location?: ModelStringInput | null,
  portfolio?: ModelStringInput | null,
  design?: ModelBooleanInput | null,
  ux?: ModelBooleanInput | null,
  motion?: ModelBooleanInput | null,
  development?: ModelBooleanInput | null,
  photography?: ModelBooleanInput | null,
  video?: ModelBooleanInput | null,
  copywriting?: ModelBooleanInput | null,
  branding?: ModelBooleanInput | null,
  rate?: ModelStringInput | null,
  referredBy?: ModelStringInput | null,
  notes?: ModelStringInput | null,
  status?: ModelStringInput | null,
  and?: Array< ModelFreelancerFilterInput | null > | null,
  or?: Array< ModelFreelancerFilterInput | null > | null,
  not?: ModelFreelancerFilterInput | null,
};

export type ModelFreelancerConnection = {
  __typename: "ModelFreelancerConnection",
  items:  Array<Freelancer >,
  nextToken?: string | null,
};

export type ModelGIFFilterInput = {
  id?: ModelIDInput | null,
  sort?: ModelIntInput | null,
  title?: ModelStringInput | null,
  image?: ModelStringInput | null,
  active?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelGIFFilterInput | null > | null,
  or?: Array< ModelGIFFilterInput | null > | null,
  not?: ModelGIFFilterInput | null,
};

export type ModelGIFConnection = {
  __typename: "ModelGIFConnection",
  items:  Array<GIF >,
  nextToken?: string | null,
};

export type ModelHolidayFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  sort?: ModelIntInput | null,
  disclaimer?: ModelStringInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  yearID?: ModelIDInput | null,
  and?: Array< ModelHolidayFilterInput | null > | null,
  or?: Array< ModelHolidayFilterInput | null > | null,
  not?: ModelHolidayFilterInput | null,
};

export type ModelMemberFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  image?: ModelStringInput | null,
  name?: ModelStringInput | null,
  nameLow?: ModelStringInput | null,
  description?: ModelStringInput | null,
  department?: ModelStringInput | null,
  reportTo?: ModelStringInput | null,
  projects?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  email?: ModelStringInput | null,
  active?: ModelBooleanInput | null,
  startDate?: ModelStringInput | null,
  officeID?: ModelIDInput | null,
  sort?: ModelIntInput | null,
  and?: Array< ModelMemberFilterInput | null > | null,
  or?: Array< ModelMemberFilterInput | null > | null,
  not?: ModelMemberFilterInput | null,
};

export type ModelMileageRateFilterInput = {
  id?: ModelIDInput | null,
  rate?: ModelFloatInput | null,
  date?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelMileageRateFilterInput | null > | null,
  or?: Array< ModelMileageRateFilterInput | null > | null,
  not?: ModelMileageRateFilterInput | null,
};

export type ModelMileageRateConnection = {
  __typename: "ModelMileageRateConnection",
  items:  Array<MileageRate >,
  nextToken?: string | null,
};

export type ModelOfficeFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  sort?: ModelIntInput | null,
  active?: ModelBooleanInput | null,
  group?: ModelStringInput | null,
  timezone?: ModelStringInput | null,
  and?: Array< ModelOfficeFilterInput | null > | null,
  or?: Array< ModelOfficeFilterInput | null > | null,
  not?: ModelOfficeFilterInput | null,
};

export type ModelOfficeConnection = {
  __typename: "ModelOfficeConnection",
  items:  Array<Office >,
  nextToken?: string | null,
};

export type ModelLocationFilterInput = {
  id?: ModelIDInput | null,
  type?: ModelStringInput | null,
  sort?: ModelIntInput | null,
  active?: ModelBooleanInput | null,
  officeID?: ModelIDInput | null,
  and?: Array< ModelLocationFilterInput | null > | null,
  or?: Array< ModelLocationFilterInput | null > | null,
  not?: ModelLocationFilterInput | null,
};

export type ModelLocationConnection = {
  __typename: "ModelLocationConnection",
  items:  Array<Location >,
  nextToken?: string | null,
};

export type ModelPageFilterInput = {
  id?: ModelIDInput | null,
  label?: ModelStringInput | null,
  status?: ModelPageStatusInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  title?: ModelStringInput | null,
  slug?: ModelStringInput | null,
  description?: ModelStringInput | null,
  eyebrow?: ModelStringInput | null,
  headline?: ModelStringInput | null,
  body?: ModelStringInput | null,
  inlineBody?: ModelStringInput | null,
  ctaText?: ModelStringInput | null,
  ctaLink?: ModelStringInput | null,
  headlineLow?: ModelStringInput | null,
  bodyLow?: ModelStringInput | null,
  layout?: ModelPageLayoutInput | null,
  pageId?: ModelIDInput | null,
  local?: ModelStringInput | null,
  publishedAt?: ModelStringInput | null,
  image?: ModelStringInput | null,
  sort?: ModelIntInput | null,
  meta?: ModelStringInput | null,
  officeId?: ModelIDInput | null,
  and?: Array< ModelPageFilterInput | null > | null,
  or?: Array< ModelPageFilterInput | null > | null,
  not?: ModelPageFilterInput | null,
};

export type ModelPolicyFilterInput = {
  id?: ModelIDInput | null,
  location?: ModelAllLocationInput | null,
  title?: ModelStringInput | null,
  acknoledgement?: ModelBooleanInput | null,
  version?: ModelStringInput | null,
  content?: ModelStringInput | null,
  and?: Array< ModelPolicyFilterInput | null > | null,
  or?: Array< ModelPolicyFilterInput | null > | null,
  not?: ModelPolicyFilterInput | null,
};

export type ModelPolicyConnection = {
  __typename: "ModelPolicyConnection",
  items:  Array<Policy >,
  nextToken?: string | null,
};

export type ModelQuickLinkFilterInput = {
  id?: ModelIDInput | null,
  pageId?: ModelIDInput | null,
  sort?: ModelIntInput | null,
  title?: ModelStringInput | null,
  body?: ModelStringInput | null,
  linkTitle?: ModelStringInput | null,
  externalLink?: ModelStringInput | null,
  linkId?: ModelIDInput | null,
  and?: Array< ModelQuickLinkFilterInput | null > | null,
  or?: Array< ModelQuickLinkFilterInput | null > | null,
  not?: ModelQuickLinkFilterInput | null,
};

export type ModelYearFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  sort?: ModelIntInput | null,
  date?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelYearFilterInput | null > | null,
  or?: Array< ModelYearFilterInput | null > | null,
  not?: ModelYearFilterInput | null,
};

export type ModelYearConnection = {
  __typename: "ModelYearConnection",
  items:  Array<Year >,
  nextToken?: string | null,
};

export type ModelIntKeyConditionInput = {
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type EmailPTOMutationVariables = {
  input?: EmailPTOInput | null,
};

export type EmailPTOMutation = {
  emailPTO?:  {
    __typename: "EmailPTOOutput",
    message?: string | null,
  } | null,
};

export type EmailExpenseReportMutationVariables = {
  input?: EmailExpenseReportInput | null,
};

export type EmailExpenseReportMutation = {
  emailExpenseReport?:  {
    __typename: "EmailPTOOutput",
    message?: string | null,
  } | null,
};

export type UserLogoutMutationVariables = {
  input?: UserLogoutInput | null,
};

export type UserLogoutMutation = {
  userLogout?:  {
    __typename: "EmailPTOOutput",
    message?: string | null,
  } | null,
};

export type SortLocationsMutationVariables = {
  input?: SortLocationsInput | null,
};

export type SortLocationsMutation = {
  sortLocations?:  {
    __typename: "SortLocationsOutput",
    items?: Array< string | null > | null,
  } | null,
};

export type SortItemsMutationVariables = {
  input?: SortItemsInput | null,
};

export type SortItemsMutation = {
  sortItems?:  {
    __typename: "SortItemsOutput",
    items?: Array< string | null > | null,
  } | null,
};

export type SortItemsV2MutationVariables = {
  input?: SortItemsV2Input | null,
};

export type SortItemsV2Mutation = {
  sortItemsV2?:  {
    __typename: "SortItemsOutput",
    items?: Array< string | null > | null,
  } | null,
};

export type ViewItemsMutationVariables = {
  input?: ViewItemsInput | null,
};

export type ViewItemsMutation = {
  viewItems?:  {
    __typename: "SortItemsOutput",
    items?: Array< string | null > | null,
  } | null,
};

export type ToggleOfficeMutationVariables = {
  input?: ToggleOfficeInput | null,
};

export type ToggleOfficeMutation = {
  toggleOffice?:  {
    __typename: "ToggleOfficeOutput",
    id?: string | null,
  } | null,
};

export type CreateOfficeTemplatesMutationVariables = {
  input?: CreateOfficeTemplatesInput | null,
};

export type CreateOfficeTemplatesMutation = {
  createOfficeTemplates?:  {
    __typename: "CreateOfficeTemplatesOutput",
    ids?: Array< string | null > | null,
  } | null,
};

export type CheckOfficesToCreateTemplatesMutationVariables = {
  input?: CheckOfficesToCreateTemplatesInput | null,
};

export type CheckOfficesToCreateTemplatesMutation = {
  checkOfficesToCreateTemplates?:  {
    __typename: "CheckOfficesToCreateTemplatesOutput",
    shouldRefetch?: boolean | null,
  } | null,
};

export type UpdateOfficeAndConnectedPagesMutationVariables = {
  input?: UpdateOfficeAndConnectedPagesInput | null,
};

export type UpdateOfficeAndConnectedPagesMutation = {
  updateOfficeAndConnectedPages?:  {
    __typename: "UpdateOfficeAndConnectedPagesOutput",
    ids?: Array< string | null > | null,
  } | null,
};

export type CreateApplicationMutationVariables = {
  input: CreateApplicationInput,
  condition?: ModelApplicationConditionInput | null,
};

export type CreateApplicationMutation = {
  createApplication?:  {
    __typename: "Application",
    id: string,
    name?: string | null,
    image?: string | null,
    username?: string | null,
    password?: string | null,
    serial?: string | null,
    path?: string | null,
    website?: string | null,
    sort?: number | null,
    type: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateApplicationMutationVariables = {
  input: UpdateApplicationInput,
  condition?: ModelApplicationConditionInput | null,
};

export type UpdateApplicationMutation = {
  updateApplication?:  {
    __typename: "Application",
    id: string,
    name?: string | null,
    image?: string | null,
    username?: string | null,
    password?: string | null,
    serial?: string | null,
    path?: string | null,
    website?: string | null,
    sort?: number | null,
    type: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteApplicationMutationVariables = {
  input: DeleteApplicationInput,
  condition?: ModelApplicationConditionInput | null,
};

export type DeleteApplicationMutation = {
  deleteApplication?:  {
    __typename: "Application",
    id: string,
    name?: string | null,
    image?: string | null,
    username?: string | null,
    password?: string | null,
    serial?: string | null,
    path?: string | null,
    website?: string | null,
    sort?: number | null,
    type: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateFAQMutationVariables = {
  input: CreateFAQInput,
  condition?: ModelFAQConditionInput | null,
};

export type CreateFAQMutation = {
  createFAQ?:  {
    __typename: "FAQ",
    id: string,
    question?: string | null,
    answer?: string | null,
    sectionID: string,
    sort?: number | null,
    officeIDs?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateFAQMutationVariables = {
  input: UpdateFAQInput,
  condition?: ModelFAQConditionInput | null,
};

export type UpdateFAQMutation = {
  updateFAQ?:  {
    __typename: "FAQ",
    id: string,
    question?: string | null,
    answer?: string | null,
    sectionID: string,
    sort?: number | null,
    officeIDs?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteFAQMutationVariables = {
  input: DeleteFAQInput,
  condition?: ModelFAQConditionInput | null,
};

export type DeleteFAQMutation = {
  deleteFAQ?:  {
    __typename: "FAQ",
    id: string,
    question?: string | null,
    answer?: string | null,
    sectionID: string,
    sort?: number | null,
    officeIDs?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateFAQsectionMutationVariables = {
  input: CreateFAQsectionInput,
  condition?: ModelFAQsectionConditionInput | null,
};

export type CreateFAQsectionMutation = {
  createFAQsection?:  {
    __typename: "FAQsection",
    id: string,
    title?: string | null,
    sort?: number | null,
    faqs?:  {
      __typename: "ModelFAQConnection",
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type UpdateFAQsectionMutationVariables = {
  input: UpdateFAQsectionInput,
  condition?: ModelFAQsectionConditionInput | null,
};

export type UpdateFAQsectionMutation = {
  updateFAQsection?:  {
    __typename: "FAQsection",
    id: string,
    title?: string | null,
    sort?: number | null,
    faqs?:  {
      __typename: "ModelFAQConnection",
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type DeleteFAQsectionMutationVariables = {
  input: DeleteFAQsectionInput,
  condition?: ModelFAQsectionConditionInput | null,
};

export type DeleteFAQsectionMutation = {
  deleteFAQsection?:  {
    __typename: "FAQsection",
    id: string,
    title?: string | null,
    sort?: number | null,
    faqs?:  {
      __typename: "ModelFAQConnection",
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type CreateFreelancerMutationVariables = {
  input: CreateFreelancerInput,
  condition?: ModelFreelancerConditionInput | null,
};

export type CreateFreelancerMutation = {
  createFreelancer?:  {
    __typename: "Freelancer",
    id: string,
    name?: string | null,
    email?: string | null,
    phone?: string | null,
    location?: string | null,
    portfolio?: string | null,
    design?: boolean | null,
    ux?: boolean | null,
    motion?: boolean | null,
    development?: boolean | null,
    photography?: boolean | null,
    video?: boolean | null,
    copywriting?: boolean | null,
    branding?: boolean | null,
    rate?: string | null,
    referredBy?: string | null,
    notes?: string | null,
    status?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateFreelancerMutationVariables = {
  input: UpdateFreelancerInput,
  condition?: ModelFreelancerConditionInput | null,
};

export type UpdateFreelancerMutation = {
  updateFreelancer?:  {
    __typename: "Freelancer",
    id: string,
    name?: string | null,
    email?: string | null,
    phone?: string | null,
    location?: string | null,
    portfolio?: string | null,
    design?: boolean | null,
    ux?: boolean | null,
    motion?: boolean | null,
    development?: boolean | null,
    photography?: boolean | null,
    video?: boolean | null,
    copywriting?: boolean | null,
    branding?: boolean | null,
    rate?: string | null,
    referredBy?: string | null,
    notes?: string | null,
    status?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteFreelancerMutationVariables = {
  input: DeleteFreelancerInput,
  condition?: ModelFreelancerConditionInput | null,
};

export type DeleteFreelancerMutation = {
  deleteFreelancer?:  {
    __typename: "Freelancer",
    id: string,
    name?: string | null,
    email?: string | null,
    phone?: string | null,
    location?: string | null,
    portfolio?: string | null,
    design?: boolean | null,
    ux?: boolean | null,
    motion?: boolean | null,
    development?: boolean | null,
    photography?: boolean | null,
    video?: boolean | null,
    copywriting?: boolean | null,
    branding?: boolean | null,
    rate?: string | null,
    referredBy?: string | null,
    notes?: string | null,
    status?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateGIFMutationVariables = {
  input: CreateGIFInput,
  condition?: ModelGIFConditionInput | null,
};

export type CreateGIFMutation = {
  createGIF?:  {
    __typename: "GIF",
    id: string,
    sort?: number | null,
    title?: string | null,
    image?: string | null,
    active?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type UpdateGIFMutationVariables = {
  input: UpdateGIFInput,
  condition?: ModelGIFConditionInput | null,
};

export type UpdateGIFMutation = {
  updateGIF?:  {
    __typename: "GIF",
    id: string,
    sort?: number | null,
    title?: string | null,
    image?: string | null,
    active?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type DeleteGIFMutationVariables = {
  input: DeleteGIFInput,
  condition?: ModelGIFConditionInput | null,
};

export type DeleteGIFMutation = {
  deleteGIF?:  {
    __typename: "GIF",
    id: string,
    sort?: number | null,
    title?: string | null,
    image?: string | null,
    active?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type CreateHolidayMutationVariables = {
  input: CreateHolidayInput,
  condition?: ModelHolidayConditionInput | null,
};

export type CreateHolidayMutation = {
  createHoliday?:  {
    __typename: "Holiday",
    id: string,
    title?: string | null,
    sort?: number | null,
    disclaimer?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    yearID: string,
  } | null,
};

export type UpdateHolidayMutationVariables = {
  input: UpdateHolidayInput,
  condition?: ModelHolidayConditionInput | null,
};

export type UpdateHolidayMutation = {
  updateHoliday?:  {
    __typename: "Holiday",
    id: string,
    title?: string | null,
    sort?: number | null,
    disclaimer?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    yearID: string,
  } | null,
};

export type DeleteHolidayMutationVariables = {
  input: DeleteHolidayInput,
  condition?: ModelHolidayConditionInput | null,
};

export type DeleteHolidayMutation = {
  deleteHoliday?:  {
    __typename: "Holiday",
    id: string,
    title?: string | null,
    sort?: number | null,
    disclaimer?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    yearID: string,
  } | null,
};

export type CreateMemberMutationVariables = {
  input: CreateMemberInput,
  condition?: ModelMemberConditionInput | null,
};

export type CreateMemberMutation = {
  createMember?:  {
    __typename: "Member",
    id: string,
    title?: string | null,
    image?: string | null,
    name: string,
    nameLow?: string | null,
    description?: string | null,
    department?: string | null,
    reportTo?: string | null,
    projects?: Array< string | null > | null,
    phone?: string | null,
    email?: string | null,
    active?: boolean | null,
    startDate?: string | null,
    officeID: string,
    office?:  {
      __typename: "Office",
      id: string,
      name?: string | null,
      sort?: number | null,
      active?: boolean | null,
      group?: string | null,
      timezone?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    sort?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateMemberMutationVariables = {
  input: UpdateMemberInput,
  condition?: ModelMemberConditionInput | null,
};

export type UpdateMemberMutation = {
  updateMember?:  {
    __typename: "Member",
    id: string,
    title?: string | null,
    image?: string | null,
    name: string,
    nameLow?: string | null,
    description?: string | null,
    department?: string | null,
    reportTo?: string | null,
    projects?: Array< string | null > | null,
    phone?: string | null,
    email?: string | null,
    active?: boolean | null,
    startDate?: string | null,
    officeID: string,
    office?:  {
      __typename: "Office",
      id: string,
      name?: string | null,
      sort?: number | null,
      active?: boolean | null,
      group?: string | null,
      timezone?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    sort?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteMemberMutationVariables = {
  input: DeleteMemberInput,
  condition?: ModelMemberConditionInput | null,
};

export type DeleteMemberMutation = {
  deleteMember?:  {
    __typename: "Member",
    id: string,
    title?: string | null,
    image?: string | null,
    name: string,
    nameLow?: string | null,
    description?: string | null,
    department?: string | null,
    reportTo?: string | null,
    projects?: Array< string | null > | null,
    phone?: string | null,
    email?: string | null,
    active?: boolean | null,
    startDate?: string | null,
    officeID: string,
    office?:  {
      __typename: "Office",
      id: string,
      name?: string | null,
      sort?: number | null,
      active?: boolean | null,
      group?: string | null,
      timezone?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    sort?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateMileageRateMutationVariables = {
  input: CreateMileageRateInput,
  condition?: ModelMileageRateConditionInput | null,
};

export type CreateMileageRateMutation = {
  createMileageRate?:  {
    __typename: "MileageRate",
    id: string,
    rate?: number | null,
    date?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type UpdateMileageRateMutationVariables = {
  input: UpdateMileageRateInput,
  condition?: ModelMileageRateConditionInput | null,
};

export type UpdateMileageRateMutation = {
  updateMileageRate?:  {
    __typename: "MileageRate",
    id: string,
    rate?: number | null,
    date?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type DeleteMileageRateMutationVariables = {
  input: DeleteMileageRateInput,
  condition?: ModelMileageRateConditionInput | null,
};

export type DeleteMileageRateMutation = {
  deleteMileageRate?:  {
    __typename: "MileageRate",
    id: string,
    rate?: number | null,
    date?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type CreateOfficeMutationVariables = {
  input: CreateOfficeInput,
  condition?: ModelOfficeConditionInput | null,
};

export type CreateOfficeMutation = {
  createOffice?:  {
    __typename: "Office",
    id: string,
    name?: string | null,
    sort?: number | null,
    active?: boolean | null,
    group?: string | null,
    timezone?: string | null,
    members?:  {
      __typename: "ModelMemberConnection",
      nextToken?: string | null,
    } | null,
    pages?:  {
      __typename: "ModelPageConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateOfficeMutationVariables = {
  input: UpdateOfficeInput,
  condition?: ModelOfficeConditionInput | null,
};

export type UpdateOfficeMutation = {
  updateOffice?:  {
    __typename: "Office",
    id: string,
    name?: string | null,
    sort?: number | null,
    active?: boolean | null,
    group?: string | null,
    timezone?: string | null,
    members?:  {
      __typename: "ModelMemberConnection",
      nextToken?: string | null,
    } | null,
    pages?:  {
      __typename: "ModelPageConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteOfficeMutationVariables = {
  input: DeleteOfficeInput,
  condition?: ModelOfficeConditionInput | null,
};

export type DeleteOfficeMutation = {
  deleteOffice?:  {
    __typename: "Office",
    id: string,
    name?: string | null,
    sort?: number | null,
    active?: boolean | null,
    group?: string | null,
    timezone?: string | null,
    members?:  {
      __typename: "ModelMemberConnection",
      nextToken?: string | null,
    } | null,
    pages?:  {
      __typename: "ModelPageConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateLocationMutationVariables = {
  input: CreateLocationInput,
  condition?: ModelLocationConditionInput | null,
};

export type CreateLocationMutation = {
  createLocation?:  {
    __typename: "Location",
    id: string,
    type: string,
    sort?: number | null,
    active?: boolean | null,
    officeID: string,
    office:  {
      __typename: "Office",
      id: string,
      name?: string | null,
      sort?: number | null,
      active?: boolean | null,
      group?: string | null,
      timezone?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateLocationMutationVariables = {
  input: UpdateLocationInput,
  condition?: ModelLocationConditionInput | null,
};

export type UpdateLocationMutation = {
  updateLocation?:  {
    __typename: "Location",
    id: string,
    type: string,
    sort?: number | null,
    active?: boolean | null,
    officeID: string,
    office:  {
      __typename: "Office",
      id: string,
      name?: string | null,
      sort?: number | null,
      active?: boolean | null,
      group?: string | null,
      timezone?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteLocationMutationVariables = {
  input: DeleteLocationInput,
  condition?: ModelLocationConditionInput | null,
};

export type DeleteLocationMutation = {
  deleteLocation?:  {
    __typename: "Location",
    id: string,
    type: string,
    sort?: number | null,
    active?: boolean | null,
    officeID: string,
    office:  {
      __typename: "Office",
      id: string,
      name?: string | null,
      sort?: number | null,
      active?: boolean | null,
      group?: string | null,
      timezone?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreatePageMutationVariables = {
  input: CreatePageInput,
  condition?: ModelPageConditionInput | null,
};

export type CreatePageMutation = {
  createPage?:  {
    __typename: "Page",
    id: string,
    label?: string | null,
    status?: PageStatus | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    title: string,
    slug: string,
    description?: string | null,
    eyebrow?: string | null,
    headline?: string | null,
    body?: string | null,
    inlineBody?: string | null,
    ctaText?: string | null,
    ctaLink?: string | null,
    headlineLow?: string | null,
    bodyLow?: string | null,
    layout?: PageLayout | null,
    quicklinks?:  {
      __typename: "ModelQuickLinkConnection",
      nextToken?: string | null,
    } | null,
    referencedQuicklinks?:  {
      __typename: "ModelQuickLinkConnection",
      nextToken?: string | null,
    } | null,
    pageId: string,
    pages?:  {
      __typename: "ModelPageConnection",
      nextToken?: string | null,
    } | null,
    parentPage?:  {
      __typename: "Page",
      id: string,
      label?: string | null,
      status?: PageStatus | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      title: string,
      slug: string,
      description?: string | null,
      eyebrow?: string | null,
      headline?: string | null,
      body?: string | null,
      inlineBody?: string | null,
      ctaText?: string | null,
      ctaLink?: string | null,
      headlineLow?: string | null,
      bodyLow?: string | null,
      layout?: PageLayout | null,
      pageId: string,
      local?: string | null,
      publishedAt?: string | null,
      image?: string | null,
      sort?: number | null,
      meta?: string | null,
      officeId?: string | null,
    } | null,
    local?: string | null,
    publishedAt?: string | null,
    image?: string | null,
    sort?: number | null,
    meta?: string | null,
    officeId?: string | null,
    office?:  {
      __typename: "Office",
      id: string,
      name?: string | null,
      sort?: number | null,
      active?: boolean | null,
      group?: string | null,
      timezone?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type UpdatePageMutationVariables = {
  input: UpdatePageInput,
  condition?: ModelPageConditionInput | null,
};

export type UpdatePageMutation = {
  updatePage?:  {
    __typename: "Page",
    id: string,
    label?: string | null,
    status?: PageStatus | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    title: string,
    slug: string,
    description?: string | null,
    eyebrow?: string | null,
    headline?: string | null,
    body?: string | null,
    inlineBody?: string | null,
    ctaText?: string | null,
    ctaLink?: string | null,
    headlineLow?: string | null,
    bodyLow?: string | null,
    layout?: PageLayout | null,
    quicklinks?:  {
      __typename: "ModelQuickLinkConnection",
      nextToken?: string | null,
    } | null,
    referencedQuicklinks?:  {
      __typename: "ModelQuickLinkConnection",
      nextToken?: string | null,
    } | null,
    pageId: string,
    pages?:  {
      __typename: "ModelPageConnection",
      nextToken?: string | null,
    } | null,
    parentPage?:  {
      __typename: "Page",
      id: string,
      label?: string | null,
      status?: PageStatus | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      title: string,
      slug: string,
      description?: string | null,
      eyebrow?: string | null,
      headline?: string | null,
      body?: string | null,
      inlineBody?: string | null,
      ctaText?: string | null,
      ctaLink?: string | null,
      headlineLow?: string | null,
      bodyLow?: string | null,
      layout?: PageLayout | null,
      pageId: string,
      local?: string | null,
      publishedAt?: string | null,
      image?: string | null,
      sort?: number | null,
      meta?: string | null,
      officeId?: string | null,
    } | null,
    local?: string | null,
    publishedAt?: string | null,
    image?: string | null,
    sort?: number | null,
    meta?: string | null,
    officeId?: string | null,
    office?:  {
      __typename: "Office",
      id: string,
      name?: string | null,
      sort?: number | null,
      active?: boolean | null,
      group?: string | null,
      timezone?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type DeletePageMutationVariables = {
  input: DeletePageInput,
  condition?: ModelPageConditionInput | null,
};

export type DeletePageMutation = {
  deletePage?:  {
    __typename: "Page",
    id: string,
    label?: string | null,
    status?: PageStatus | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    title: string,
    slug: string,
    description?: string | null,
    eyebrow?: string | null,
    headline?: string | null,
    body?: string | null,
    inlineBody?: string | null,
    ctaText?: string | null,
    ctaLink?: string | null,
    headlineLow?: string | null,
    bodyLow?: string | null,
    layout?: PageLayout | null,
    quicklinks?:  {
      __typename: "ModelQuickLinkConnection",
      nextToken?: string | null,
    } | null,
    referencedQuicklinks?:  {
      __typename: "ModelQuickLinkConnection",
      nextToken?: string | null,
    } | null,
    pageId: string,
    pages?:  {
      __typename: "ModelPageConnection",
      nextToken?: string | null,
    } | null,
    parentPage?:  {
      __typename: "Page",
      id: string,
      label?: string | null,
      status?: PageStatus | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      title: string,
      slug: string,
      description?: string | null,
      eyebrow?: string | null,
      headline?: string | null,
      body?: string | null,
      inlineBody?: string | null,
      ctaText?: string | null,
      ctaLink?: string | null,
      headlineLow?: string | null,
      bodyLow?: string | null,
      layout?: PageLayout | null,
      pageId: string,
      local?: string | null,
      publishedAt?: string | null,
      image?: string | null,
      sort?: number | null,
      meta?: string | null,
      officeId?: string | null,
    } | null,
    local?: string | null,
    publishedAt?: string | null,
    image?: string | null,
    sort?: number | null,
    meta?: string | null,
    officeId?: string | null,
    office?:  {
      __typename: "Office",
      id: string,
      name?: string | null,
      sort?: number | null,
      active?: boolean | null,
      group?: string | null,
      timezone?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type CreatePolicyMutationVariables = {
  input: CreatePolicyInput,
  condition?: ModelPolicyConditionInput | null,
};

export type CreatePolicyMutation = {
  createPolicy?:  {
    __typename: "Policy",
    id: string,
    location?: AllLocation | null,
    title?: string | null,
    acknoledgement?: boolean | null,
    version?: string | null,
    content?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdatePolicyMutationVariables = {
  input: UpdatePolicyInput,
  condition?: ModelPolicyConditionInput | null,
};

export type UpdatePolicyMutation = {
  updatePolicy?:  {
    __typename: "Policy",
    id: string,
    location?: AllLocation | null,
    title?: string | null,
    acknoledgement?: boolean | null,
    version?: string | null,
    content?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeletePolicyMutationVariables = {
  input: DeletePolicyInput,
  condition?: ModelPolicyConditionInput | null,
};

export type DeletePolicyMutation = {
  deletePolicy?:  {
    __typename: "Policy",
    id: string,
    location?: AllLocation | null,
    title?: string | null,
    acknoledgement?: boolean | null,
    version?: string | null,
    content?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateQuickLinkMutationVariables = {
  input: CreateQuickLinkInput,
  condition?: ModelQuickLinkConditionInput | null,
};

export type CreateQuickLinkMutation = {
  createQuickLink?:  {
    __typename: "QuickLink",
    id: string,
    pageId: string,
    page?:  {
      __typename: "Page",
      id: string,
      label?: string | null,
      status?: PageStatus | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      title: string,
      slug: string,
      description?: string | null,
      eyebrow?: string | null,
      headline?: string | null,
      body?: string | null,
      inlineBody?: string | null,
      ctaText?: string | null,
      ctaLink?: string | null,
      headlineLow?: string | null,
      bodyLow?: string | null,
      layout?: PageLayout | null,
      pageId: string,
      local?: string | null,
      publishedAt?: string | null,
      image?: string | null,
      sort?: number | null,
      meta?: string | null,
      officeId?: string | null,
    } | null,
    sort?: number | null,
    title?: string | null,
    body?: string | null,
    linkTitle?: string | null,
    externalLink?: string | null,
    linkId?: string | null,
    link?:  {
      __typename: "Page",
      id: string,
      label?: string | null,
      status?: PageStatus | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      title: string,
      slug: string,
      description?: string | null,
      eyebrow?: string | null,
      headline?: string | null,
      body?: string | null,
      inlineBody?: string | null,
      ctaText?: string | null,
      ctaLink?: string | null,
      headlineLow?: string | null,
      bodyLow?: string | null,
      layout?: PageLayout | null,
      pageId: string,
      local?: string | null,
      publishedAt?: string | null,
      image?: string | null,
      sort?: number | null,
      meta?: string | null,
      officeId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateQuickLinkMutationVariables = {
  input: UpdateQuickLinkInput,
  condition?: ModelQuickLinkConditionInput | null,
};

export type UpdateQuickLinkMutation = {
  updateQuickLink?:  {
    __typename: "QuickLink",
    id: string,
    pageId: string,
    page?:  {
      __typename: "Page",
      id: string,
      label?: string | null,
      status?: PageStatus | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      title: string,
      slug: string,
      description?: string | null,
      eyebrow?: string | null,
      headline?: string | null,
      body?: string | null,
      inlineBody?: string | null,
      ctaText?: string | null,
      ctaLink?: string | null,
      headlineLow?: string | null,
      bodyLow?: string | null,
      layout?: PageLayout | null,
      pageId: string,
      local?: string | null,
      publishedAt?: string | null,
      image?: string | null,
      sort?: number | null,
      meta?: string | null,
      officeId?: string | null,
    } | null,
    sort?: number | null,
    title?: string | null,
    body?: string | null,
    linkTitle?: string | null,
    externalLink?: string | null,
    linkId?: string | null,
    link?:  {
      __typename: "Page",
      id: string,
      label?: string | null,
      status?: PageStatus | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      title: string,
      slug: string,
      description?: string | null,
      eyebrow?: string | null,
      headline?: string | null,
      body?: string | null,
      inlineBody?: string | null,
      ctaText?: string | null,
      ctaLink?: string | null,
      headlineLow?: string | null,
      bodyLow?: string | null,
      layout?: PageLayout | null,
      pageId: string,
      local?: string | null,
      publishedAt?: string | null,
      image?: string | null,
      sort?: number | null,
      meta?: string | null,
      officeId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteQuickLinkMutationVariables = {
  input: DeleteQuickLinkInput,
  condition?: ModelQuickLinkConditionInput | null,
};

export type DeleteQuickLinkMutation = {
  deleteQuickLink?:  {
    __typename: "QuickLink",
    id: string,
    pageId: string,
    page?:  {
      __typename: "Page",
      id: string,
      label?: string | null,
      status?: PageStatus | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      title: string,
      slug: string,
      description?: string | null,
      eyebrow?: string | null,
      headline?: string | null,
      body?: string | null,
      inlineBody?: string | null,
      ctaText?: string | null,
      ctaLink?: string | null,
      headlineLow?: string | null,
      bodyLow?: string | null,
      layout?: PageLayout | null,
      pageId: string,
      local?: string | null,
      publishedAt?: string | null,
      image?: string | null,
      sort?: number | null,
      meta?: string | null,
      officeId?: string | null,
    } | null,
    sort?: number | null,
    title?: string | null,
    body?: string | null,
    linkTitle?: string | null,
    externalLink?: string | null,
    linkId?: string | null,
    link?:  {
      __typename: "Page",
      id: string,
      label?: string | null,
      status?: PageStatus | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      title: string,
      slug: string,
      description?: string | null,
      eyebrow?: string | null,
      headline?: string | null,
      body?: string | null,
      inlineBody?: string | null,
      ctaText?: string | null,
      ctaLink?: string | null,
      headlineLow?: string | null,
      bodyLow?: string | null,
      layout?: PageLayout | null,
      pageId: string,
      local?: string | null,
      publishedAt?: string | null,
      image?: string | null,
      sort?: number | null,
      meta?: string | null,
      officeId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateYearMutationVariables = {
  input: CreateYearInput,
  condition?: ModelYearConditionInput | null,
};

export type CreateYearMutation = {
  createYear?:  {
    __typename: "Year",
    id: string,
    title?: string | null,
    sort?: number | null,
    date?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    holidays?:  {
      __typename: "ModelHolidayConnection",
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateYearMutationVariables = {
  input: UpdateYearInput,
  condition?: ModelYearConditionInput | null,
};

export type UpdateYearMutation = {
  updateYear?:  {
    __typename: "Year",
    id: string,
    title?: string | null,
    sort?: number | null,
    date?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    holidays?:  {
      __typename: "ModelHolidayConnection",
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteYearMutationVariables = {
  input: DeleteYearInput,
  condition?: ModelYearConditionInput | null,
};

export type DeleteYearMutation = {
  deleteYear?:  {
    __typename: "Year",
    id: string,
    title?: string | null,
    sort?: number | null,
    date?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    holidays?:  {
      __typename: "ModelHolidayConnection",
      nextToken?: string | null,
    } | null,
  } | null,
};

export type GetEventsQueryVariables = {
  input?: GetEventsInput | null,
};

export type GetEventsQuery = {
  getEvents?:  {
    __typename: "GetEventOutput",
    events?:  Array< {
      __typename: "EventsOutput",
      startDate?: string | null,
      endDate?: string | null,
      title?: string | null,
      id?: string | null,
    } | null > | null,
  } | null,
};

export type GetApplicationQueryVariables = {
  id: string,
};

export type GetApplicationQuery = {
  getApplication?:  {
    __typename: "Application",
    id: string,
    name?: string | null,
    image?: string | null,
    username?: string | null,
    password?: string | null,
    serial?: string | null,
    path?: string | null,
    website?: string | null,
    sort?: number | null,
    type: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListApplicationsQueryVariables = {
  filter?: ModelApplicationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListApplicationsQuery = {
  listApplications?:  {
    __typename: "ModelApplicationConnection",
    items:  Array< {
      __typename: "Application",
      id: string,
      name?: string | null,
      image?: string | null,
      username?: string | null,
      password?: string | null,
      serial?: string | null,
      path?: string | null,
      website?: string | null,
      sort?: number | null,
      type: string,
      createdAt: string,
      updatedAt: string,
    } >,
    nextToken?: string | null,
  } | null,
};

export type GetFAQQueryVariables = {
  id: string,
};

export type GetFAQQuery = {
  getFAQ?:  {
    __typename: "FAQ",
    id: string,
    question?: string | null,
    answer?: string | null,
    sectionID: string,
    sort?: number | null,
    officeIDs?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListFAQSQueryVariables = {
  filter?: ModelFAQFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFAQSQuery = {
  listFAQS?:  {
    __typename: "ModelFAQConnection",
    items:  Array< {
      __typename: "FAQ",
      id: string,
      question?: string | null,
      answer?: string | null,
      sectionID: string,
      sort?: number | null,
      officeIDs?: Array< string > | null,
      createdAt: string,
      updatedAt: string,
    } >,
    nextToken?: string | null,
  } | null,
};

export type GetFAQsectionQueryVariables = {
  id: string,
};

export type GetFAQsectionQuery = {
  getFAQsection?:  {
    __typename: "FAQsection",
    id: string,
    title?: string | null,
    sort?: number | null,
    faqs?:  {
      __typename: "ModelFAQConnection",
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type ListFAQsectionsQueryVariables = {
  filter?: ModelFAQsectionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFAQsectionsQuery = {
  listFAQsections?:  {
    __typename: "ModelFAQsectionConnection",
    items:  Array< {
      __typename: "FAQsection",
      id: string,
      title?: string | null,
      sort?: number | null,
      createdAt?: string | null,
      updatedAt: string,
    } >,
    nextToken?: string | null,
  } | null,
};

export type GetFreelancerQueryVariables = {
  id: string,
};

export type GetFreelancerQuery = {
  getFreelancer?:  {
    __typename: "Freelancer",
    id: string,
    name?: string | null,
    email?: string | null,
    phone?: string | null,
    location?: string | null,
    portfolio?: string | null,
    design?: boolean | null,
    ux?: boolean | null,
    motion?: boolean | null,
    development?: boolean | null,
    photography?: boolean | null,
    video?: boolean | null,
    copywriting?: boolean | null,
    branding?: boolean | null,
    rate?: string | null,
    referredBy?: string | null,
    notes?: string | null,
    status?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListFreelancersQueryVariables = {
  filter?: ModelFreelancerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFreelancersQuery = {
  listFreelancers?:  {
    __typename: "ModelFreelancerConnection",
    items:  Array< {
      __typename: "Freelancer",
      id: string,
      name?: string | null,
      email?: string | null,
      phone?: string | null,
      location?: string | null,
      portfolio?: string | null,
      design?: boolean | null,
      ux?: boolean | null,
      motion?: boolean | null,
      development?: boolean | null,
      photography?: boolean | null,
      video?: boolean | null,
      copywriting?: boolean | null,
      branding?: boolean | null,
      rate?: string | null,
      referredBy?: string | null,
      notes?: string | null,
      status?: string | null,
      createdAt: string,
      updatedAt: string,
    } >,
    nextToken?: string | null,
  } | null,
};

export type GetGIFQueryVariables = {
  id: string,
};

export type GetGIFQuery = {
  getGIF?:  {
    __typename: "GIF",
    id: string,
    sort?: number | null,
    title?: string | null,
    image?: string | null,
    active?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type ListGIFSQueryVariables = {
  filter?: ModelGIFFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListGIFSQuery = {
  listGIFS?:  {
    __typename: "ModelGIFConnection",
    items:  Array< {
      __typename: "GIF",
      id: string,
      sort?: number | null,
      title?: string | null,
      image?: string | null,
      active?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } >,
    nextToken?: string | null,
  } | null,
};

export type GetHolidayQueryVariables = {
  id: string,
};

export type GetHolidayQuery = {
  getHoliday?:  {
    __typename: "Holiday",
    id: string,
    title?: string | null,
    sort?: number | null,
    disclaimer?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    yearID: string,
  } | null,
};

export type ListHolidaysQueryVariables = {
  filter?: ModelHolidayFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListHolidaysQuery = {
  listHolidays?:  {
    __typename: "ModelHolidayConnection",
    items:  Array< {
      __typename: "Holiday",
      id: string,
      title?: string | null,
      sort?: number | null,
      disclaimer?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      yearID: string,
    } >,
    nextToken?: string | null,
  } | null,
};

export type GetMemberQueryVariables = {
  id: string,
};

export type GetMemberQuery = {
  getMember?:  {
    __typename: "Member",
    id: string,
    title?: string | null,
    image?: string | null,
    name: string,
    nameLow?: string | null,
    description?: string | null,
    department?: string | null,
    reportTo?: string | null,
    projects?: Array< string | null > | null,
    phone?: string | null,
    email?: string | null,
    active?: boolean | null,
    startDate?: string | null,
    officeID: string,
    office?:  {
      __typename: "Office",
      id: string,
      name?: string | null,
      sort?: number | null,
      active?: boolean | null,
      group?: string | null,
      timezone?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    sort?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListMembersQueryVariables = {
  filter?: ModelMemberFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMembersQuery = {
  listMembers?:  {
    __typename: "ModelMemberConnection",
    items:  Array< {
      __typename: "Member",
      id: string,
      title?: string | null,
      image?: string | null,
      name: string,
      nameLow?: string | null,
      description?: string | null,
      department?: string | null,
      reportTo?: string | null,
      projects?: Array< string | null > | null,
      phone?: string | null,
      email?: string | null,
      active?: boolean | null,
      startDate?: string | null,
      officeID: string,
      sort?: number | null,
      createdAt: string,
      updatedAt: string,
    } >,
    nextToken?: string | null,
  } | null,
};

export type GetMileageRateQueryVariables = {
  id: string,
};

export type GetMileageRateQuery = {
  getMileageRate?:  {
    __typename: "MileageRate",
    id: string,
    rate?: number | null,
    date?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type ListMileageRatesQueryVariables = {
  filter?: ModelMileageRateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMileageRatesQuery = {
  listMileageRates?:  {
    __typename: "ModelMileageRateConnection",
    items:  Array< {
      __typename: "MileageRate",
      id: string,
      rate?: number | null,
      date?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } >,
    nextToken?: string | null,
  } | null,
};

export type GetOfficeQueryVariables = {
  id: string,
};

export type GetOfficeQuery = {
  getOffice?:  {
    __typename: "Office",
    id: string,
    name?: string | null,
    sort?: number | null,
    active?: boolean | null,
    group?: string | null,
    timezone?: string | null,
    members?:  {
      __typename: "ModelMemberConnection",
      nextToken?: string | null,
    } | null,
    pages?:  {
      __typename: "ModelPageConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListOfficesQueryVariables = {
  filter?: ModelOfficeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOfficesQuery = {
  listOffices?:  {
    __typename: "ModelOfficeConnection",
    items:  Array< {
      __typename: "Office",
      id: string,
      name?: string | null,
      sort?: number | null,
      active?: boolean | null,
      group?: string | null,
      timezone?: string | null,
      createdAt: string,
      updatedAt: string,
    } >,
    nextToken?: string | null,
  } | null,
};

export type GetLocationQueryVariables = {
  id: string,
};

export type GetLocationQuery = {
  getLocation?:  {
    __typename: "Location",
    id: string,
    type: string,
    sort?: number | null,
    active?: boolean | null,
    officeID: string,
    office:  {
      __typename: "Office",
      id: string,
      name?: string | null,
      sort?: number | null,
      active?: boolean | null,
      group?: string | null,
      timezone?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListLocationsQueryVariables = {
  filter?: ModelLocationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListLocationsQuery = {
  listLocations?:  {
    __typename: "ModelLocationConnection",
    items:  Array< {
      __typename: "Location",
      id: string,
      type: string,
      sort?: number | null,
      active?: boolean | null,
      officeID: string,
      createdAt: string,
      updatedAt: string,
    } >,
    nextToken?: string | null,
  } | null,
};

export type GetPageQueryVariables = {
  id: string,
};

export type GetPageQuery = {
  getPage?:  {
    __typename: "Page",
    id: string,
    label?: string | null,
    status?: PageStatus | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    title: string,
    slug: string,
    description?: string | null,
    eyebrow?: string | null,
    headline?: string | null,
    body?: string | null,
    inlineBody?: string | null,
    ctaText?: string | null,
    ctaLink?: string | null,
    headlineLow?: string | null,
    bodyLow?: string | null,
    layout?: PageLayout | null,
    quicklinks?:  {
      __typename: "ModelQuickLinkConnection",
      nextToken?: string | null,
    } | null,
    referencedQuicklinks?:  {
      __typename: "ModelQuickLinkConnection",
      nextToken?: string | null,
    } | null,
    pageId: string,
    pages?:  {
      __typename: "ModelPageConnection",
      nextToken?: string | null,
    } | null,
    parentPage?:  {
      __typename: "Page",
      id: string,
      label?: string | null,
      status?: PageStatus | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      title: string,
      slug: string,
      description?: string | null,
      eyebrow?: string | null,
      headline?: string | null,
      body?: string | null,
      inlineBody?: string | null,
      ctaText?: string | null,
      ctaLink?: string | null,
      headlineLow?: string | null,
      bodyLow?: string | null,
      layout?: PageLayout | null,
      pageId: string,
      local?: string | null,
      publishedAt?: string | null,
      image?: string | null,
      sort?: number | null,
      meta?: string | null,
      officeId?: string | null,
    } | null,
    local?: string | null,
    publishedAt?: string | null,
    image?: string | null,
    sort?: number | null,
    meta?: string | null,
    officeId?: string | null,
    office?:  {
      __typename: "Office",
      id: string,
      name?: string | null,
      sort?: number | null,
      active?: boolean | null,
      group?: string | null,
      timezone?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type ListPagesQueryVariables = {
  filter?: ModelPageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPagesQuery = {
  listPages?:  {
    __typename: "ModelPageConnection",
    items:  Array< {
      __typename: "Page",
      id: string,
      label?: string | null,
      status?: PageStatus | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      title: string,
      slug: string,
      description?: string | null,
      eyebrow?: string | null,
      headline?: string | null,
      body?: string | null,
      inlineBody?: string | null,
      ctaText?: string | null,
      ctaLink?: string | null,
      headlineLow?: string | null,
      bodyLow?: string | null,
      layout?: PageLayout | null,
      pageId: string,
      local?: string | null,
      publishedAt?: string | null,
      image?: string | null,
      sort?: number | null,
      meta?: string | null,
      officeId?: string | null,
    } >,
    nextToken?: string | null,
  } | null,
};

export type GetPolicyQueryVariables = {
  id: string,
};

export type GetPolicyQuery = {
  getPolicy?:  {
    __typename: "Policy",
    id: string,
    location?: AllLocation | null,
    title?: string | null,
    acknoledgement?: boolean | null,
    version?: string | null,
    content?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListPoliciesQueryVariables = {
  filter?: ModelPolicyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPoliciesQuery = {
  listPolicies?:  {
    __typename: "ModelPolicyConnection",
    items:  Array< {
      __typename: "Policy",
      id: string,
      location?: AllLocation | null,
      title?: string | null,
      acknoledgement?: boolean | null,
      version?: string | null,
      content?: string | null,
      createdAt: string,
      updatedAt: string,
    } >,
    nextToken?: string | null,
  } | null,
};

export type GetQuickLinkQueryVariables = {
  id: string,
};

export type GetQuickLinkQuery = {
  getQuickLink?:  {
    __typename: "QuickLink",
    id: string,
    pageId: string,
    page?:  {
      __typename: "Page",
      id: string,
      label?: string | null,
      status?: PageStatus | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      title: string,
      slug: string,
      description?: string | null,
      eyebrow?: string | null,
      headline?: string | null,
      body?: string | null,
      inlineBody?: string | null,
      ctaText?: string | null,
      ctaLink?: string | null,
      headlineLow?: string | null,
      bodyLow?: string | null,
      layout?: PageLayout | null,
      pageId: string,
      local?: string | null,
      publishedAt?: string | null,
      image?: string | null,
      sort?: number | null,
      meta?: string | null,
      officeId?: string | null,
    } | null,
    sort?: number | null,
    title?: string | null,
    body?: string | null,
    linkTitle?: string | null,
    externalLink?: string | null,
    linkId?: string | null,
    link?:  {
      __typename: "Page",
      id: string,
      label?: string | null,
      status?: PageStatus | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      title: string,
      slug: string,
      description?: string | null,
      eyebrow?: string | null,
      headline?: string | null,
      body?: string | null,
      inlineBody?: string | null,
      ctaText?: string | null,
      ctaLink?: string | null,
      headlineLow?: string | null,
      bodyLow?: string | null,
      layout?: PageLayout | null,
      pageId: string,
      local?: string | null,
      publishedAt?: string | null,
      image?: string | null,
      sort?: number | null,
      meta?: string | null,
      officeId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListQuickLinksQueryVariables = {
  filter?: ModelQuickLinkFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListQuickLinksQuery = {
  listQuickLinks?:  {
    __typename: "ModelQuickLinkConnection",
    items:  Array< {
      __typename: "QuickLink",
      id: string,
      pageId: string,
      sort?: number | null,
      title?: string | null,
      body?: string | null,
      linkTitle?: string | null,
      externalLink?: string | null,
      linkId?: string | null,
      createdAt: string,
      updatedAt: string,
    } >,
    nextToken?: string | null,
  } | null,
};

export type GetYearQueryVariables = {
  id: string,
};

export type GetYearQuery = {
  getYear?:  {
    __typename: "Year",
    id: string,
    title?: string | null,
    sort?: number | null,
    date?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    holidays?:  {
      __typename: "ModelHolidayConnection",
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListYearsQueryVariables = {
  filter?: ModelYearFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListYearsQuery = {
  listYears?:  {
    __typename: "ModelYearConnection",
    items:  Array< {
      __typename: "Year",
      id: string,
      title?: string | null,
      sort?: number | null,
      date?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } >,
    nextToken?: string | null,
  } | null,
};

export type ListApplicationByTypeQueryVariables = {
  type?: string | null,
  sort?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelApplicationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListApplicationByTypeQuery = {
  listApplicationByType?:  {
    __typename: "ModelApplicationConnection",
    items:  Array< {
      __typename: "Application",
      id: string,
      name?: string | null,
      image?: string | null,
      username?: string | null,
      password?: string | null,
      serial?: string | null,
      path?: string | null,
      website?: string | null,
      sort?: number | null,
      type: string,
      createdAt: string,
      updatedAt: string,
    } >,
    nextToken?: string | null,
  } | null,
};

export type ListFAQBySectionQueryVariables = {
  sectionID?: string | null,
  sort?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFAQFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFAQBySectionQuery = {
  listFAQBySection?:  {
    __typename: "ModelFAQConnection",
    items:  Array< {
      __typename: "FAQ",
      id: string,
      question?: string | null,
      answer?: string | null,
      sectionID: string,
      sort?: number | null,
      officeIDs?: Array< string > | null,
      createdAt: string,
      updatedAt: string,
    } >,
    nextToken?: string | null,
  } | null,
};

export type FaqSectionByTitleQueryVariables = {
  title?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFAQsectionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FaqSectionByTitleQuery = {
  faqSectionByTitle?:  {
    __typename: "ModelFAQsectionConnection",
    items:  Array< {
      __typename: "FAQsection",
      id: string,
      title?: string | null,
      sort?: number | null,
      createdAt?: string | null,
      updatedAt: string,
    } >,
    nextToken?: string | null,
  } | null,
};

export type ListMembersByOfficeQueryVariables = {
  officeID?: string | null,
  sort?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMemberFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMembersByOfficeQuery = {
  listMembersByOffice?:  {
    __typename: "ModelMemberConnection",
    items:  Array< {
      __typename: "Member",
      id: string,
      title?: string | null,
      image?: string | null,
      name: string,
      nameLow?: string | null,
      description?: string | null,
      department?: string | null,
      reportTo?: string | null,
      projects?: Array< string | null > | null,
      phone?: string | null,
      email?: string | null,
      active?: boolean | null,
      startDate?: string | null,
      officeID: string,
      sort?: number | null,
      createdAt: string,
      updatedAt: string,
    } >,
    nextToken?: string | null,
  } | null,
};

export type ListLocationByTypeQueryVariables = {
  type?: string | null,
  sort?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLocationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListLocationByTypeQuery = {
  listLocationByType?:  {
    __typename: "ModelLocationConnection",
    items:  Array< {
      __typename: "Location",
      id: string,
      type: string,
      sort?: number | null,
      active?: boolean | null,
      officeID: string,
      createdAt: string,
      updatedAt: string,
    } >,
    nextToken?: string | null,
  } | null,
};

export type ListLocationByOfficeQueryVariables = {
  officeID?: string | null,
  sort?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLocationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListLocationByOfficeQuery = {
  listLocationByOffice?:  {
    __typename: "ModelLocationConnection",
    items:  Array< {
      __typename: "Location",
      id: string,
      type: string,
      sort?: number | null,
      active?: boolean | null,
      officeID: string,
      createdAt: string,
      updatedAt: string,
    } >,
    nextToken?: string | null,
  } | null,
};

export type ListPagesByPageIdQueryVariables = {
  pageId?: string | null,
  sort?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPagesByPageIdQuery = {
  listPagesByPageId?:  {
    __typename: "ModelPageConnection",
    items:  Array< {
      __typename: "Page",
      id: string,
      label?: string | null,
      status?: PageStatus | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      title: string,
      slug: string,
      description?: string | null,
      eyebrow?: string | null,
      headline?: string | null,
      body?: string | null,
      inlineBody?: string | null,
      ctaText?: string | null,
      ctaLink?: string | null,
      headlineLow?: string | null,
      bodyLow?: string | null,
      layout?: PageLayout | null,
      pageId: string,
      local?: string | null,
      publishedAt?: string | null,
      image?: string | null,
      sort?: number | null,
      meta?: string | null,
      officeId?: string | null,
    } >,
    nextToken?: string | null,
  } | null,
};

export type GetPageBySlugQueryVariables = {
  slug?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetPageBySlugQuery = {
  getPageBySlug?:  {
    __typename: "ModelPageConnection",
    items:  Array< {
      __typename: "Page",
      id: string,
      label?: string | null,
      status?: PageStatus | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      title: string,
      slug: string,
      description?: string | null,
      eyebrow?: string | null,
      headline?: string | null,
      body?: string | null,
      inlineBody?: string | null,
      ctaText?: string | null,
      ctaLink?: string | null,
      headlineLow?: string | null,
      bodyLow?: string | null,
      layout?: PageLayout | null,
      pageId: string,
      local?: string | null,
      publishedAt?: string | null,
      image?: string | null,
      sort?: number | null,
      meta?: string | null,
      officeId?: string | null,
    } >,
    nextToken?: string | null,
  } | null,
};

export type ListPagesByStatusAndHeadlineQueryVariables = {
  status?: PageStatus | null,
  headline?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPagesByStatusAndHeadlineQuery = {
  listPagesByStatusAndHeadline?:  {
    __typename: "ModelPageConnection",
    items:  Array< {
      __typename: "Page",
      id: string,
      label?: string | null,
      status?: PageStatus | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      title: string,
      slug: string,
      description?: string | null,
      eyebrow?: string | null,
      headline?: string | null,
      body?: string | null,
      inlineBody?: string | null,
      ctaText?: string | null,
      ctaLink?: string | null,
      headlineLow?: string | null,
      bodyLow?: string | null,
      layout?: PageLayout | null,
      pageId: string,
      local?: string | null,
      publishedAt?: string | null,
      image?: string | null,
      sort?: number | null,
      meta?: string | null,
      officeId?: string | null,
    } >,
    nextToken?: string | null,
  } | null,
};

export type ListPagesByOfficeQueryVariables = {
  officeId?: string | null,
  sort?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPagesByOfficeQuery = {
  listPagesByOffice?:  {
    __typename: "ModelPageConnection",
    items:  Array< {
      __typename: "Page",
      id: string,
      label?: string | null,
      status?: PageStatus | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      title: string,
      slug: string,
      description?: string | null,
      eyebrow?: string | null,
      headline?: string | null,
      body?: string | null,
      inlineBody?: string | null,
      ctaText?: string | null,
      ctaLink?: string | null,
      headlineLow?: string | null,
      bodyLow?: string | null,
      layout?: PageLayout | null,
      pageId: string,
      local?: string | null,
      publishedAt?: string | null,
      image?: string | null,
      sort?: number | null,
      meta?: string | null,
      officeId?: string | null,
    } >,
    nextToken?: string | null,
  } | null,
};

export type ListPagesByLayoutQueryVariables = {
  layout?: PageLayout | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPagesByLayoutQuery = {
  listPagesByLayout?:  {
    __typename: "ModelPageConnection",
    items:  Array< {
      __typename: "Page",
      id: string,
      label?: string | null,
      status?: PageStatus | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      title: string,
      slug: string,
      description?: string | null,
      eyebrow?: string | null,
      headline?: string | null,
      body?: string | null,
      inlineBody?: string | null,
      ctaText?: string | null,
      ctaLink?: string | null,
      headlineLow?: string | null,
      bodyLow?: string | null,
      layout?: PageLayout | null,
      pageId: string,
      local?: string | null,
      publishedAt?: string | null,
      image?: string | null,
      sort?: number | null,
      meta?: string | null,
      officeId?: string | null,
    } >,
    nextToken?: string | null,
  } | null,
};

export type ListQuicklinksByPageIdQueryVariables = {
  pageId?: string | null,
  sort?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelQuickLinkFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListQuicklinksByPageIdQuery = {
  listQuicklinksByPageId?:  {
    __typename: "ModelQuickLinkConnection",
    items:  Array< {
      __typename: "QuickLink",
      id: string,
      pageId: string,
      sort?: number | null,
      title?: string | null,
      body?: string | null,
      linkTitle?: string | null,
      externalLink?: string | null,
      linkId?: string | null,
      createdAt: string,
      updatedAt: string,
    } >,
    nextToken?: string | null,
  } | null,
};

export type ListQuicklinksByLinkIdQueryVariables = {
  linkId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelQuickLinkFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListQuicklinksByLinkIdQuery = {
  listQuicklinksByLinkId?:  {
    __typename: "ModelQuickLinkConnection",
    items:  Array< {
      __typename: "QuickLink",
      id: string,
      pageId: string,
      sort?: number | null,
      title?: string | null,
      body?: string | null,
      linkTitle?: string | null,
      externalLink?: string | null,
      linkId?: string | null,
      createdAt: string,
      updatedAt: string,
    } >,
    nextToken?: string | null,
  } | null,
};

export type OnCreateApplicationSubscription = {
  onCreateApplication?:  {
    __typename: "Application",
    id: string,
    name?: string | null,
    image?: string | null,
    username?: string | null,
    password?: string | null,
    serial?: string | null,
    path?: string | null,
    website?: string | null,
    sort?: number | null,
    type: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateApplicationSubscription = {
  onUpdateApplication?:  {
    __typename: "Application",
    id: string,
    name?: string | null,
    image?: string | null,
    username?: string | null,
    password?: string | null,
    serial?: string | null,
    path?: string | null,
    website?: string | null,
    sort?: number | null,
    type: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteApplicationSubscription = {
  onDeleteApplication?:  {
    __typename: "Application",
    id: string,
    name?: string | null,
    image?: string | null,
    username?: string | null,
    password?: string | null,
    serial?: string | null,
    path?: string | null,
    website?: string | null,
    sort?: number | null,
    type: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateFAQSubscription = {
  onCreateFAQ?:  {
    __typename: "FAQ",
    id: string,
    question?: string | null,
    answer?: string | null,
    sectionID: string,
    sort?: number | null,
    officeIDs?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateFAQSubscription = {
  onUpdateFAQ?:  {
    __typename: "FAQ",
    id: string,
    question?: string | null,
    answer?: string | null,
    sectionID: string,
    sort?: number | null,
    officeIDs?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteFAQSubscription = {
  onDeleteFAQ?:  {
    __typename: "FAQ",
    id: string,
    question?: string | null,
    answer?: string | null,
    sectionID: string,
    sort?: number | null,
    officeIDs?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateFAQsectionSubscription = {
  onCreateFAQsection?:  {
    __typename: "FAQsection",
    id: string,
    title?: string | null,
    sort?: number | null,
    faqs?:  {
      __typename: "ModelFAQConnection",
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateFAQsectionSubscription = {
  onUpdateFAQsection?:  {
    __typename: "FAQsection",
    id: string,
    title?: string | null,
    sort?: number | null,
    faqs?:  {
      __typename: "ModelFAQConnection",
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type OnDeleteFAQsectionSubscription = {
  onDeleteFAQsection?:  {
    __typename: "FAQsection",
    id: string,
    title?: string | null,
    sort?: number | null,
    faqs?:  {
      __typename: "ModelFAQConnection",
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type OnCreateFreelancerSubscription = {
  onCreateFreelancer?:  {
    __typename: "Freelancer",
    id: string,
    name?: string | null,
    email?: string | null,
    phone?: string | null,
    location?: string | null,
    portfolio?: string | null,
    design?: boolean | null,
    ux?: boolean | null,
    motion?: boolean | null,
    development?: boolean | null,
    photography?: boolean | null,
    video?: boolean | null,
    copywriting?: boolean | null,
    branding?: boolean | null,
    rate?: string | null,
    referredBy?: string | null,
    notes?: string | null,
    status?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateFreelancerSubscription = {
  onUpdateFreelancer?:  {
    __typename: "Freelancer",
    id: string,
    name?: string | null,
    email?: string | null,
    phone?: string | null,
    location?: string | null,
    portfolio?: string | null,
    design?: boolean | null,
    ux?: boolean | null,
    motion?: boolean | null,
    development?: boolean | null,
    photography?: boolean | null,
    video?: boolean | null,
    copywriting?: boolean | null,
    branding?: boolean | null,
    rate?: string | null,
    referredBy?: string | null,
    notes?: string | null,
    status?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteFreelancerSubscription = {
  onDeleteFreelancer?:  {
    __typename: "Freelancer",
    id: string,
    name?: string | null,
    email?: string | null,
    phone?: string | null,
    location?: string | null,
    portfolio?: string | null,
    design?: boolean | null,
    ux?: boolean | null,
    motion?: boolean | null,
    development?: boolean | null,
    photography?: boolean | null,
    video?: boolean | null,
    copywriting?: boolean | null,
    branding?: boolean | null,
    rate?: string | null,
    referredBy?: string | null,
    notes?: string | null,
    status?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateGIFSubscription = {
  onCreateGIF?:  {
    __typename: "GIF",
    id: string,
    sort?: number | null,
    title?: string | null,
    image?: string | null,
    active?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnUpdateGIFSubscription = {
  onUpdateGIF?:  {
    __typename: "GIF",
    id: string,
    sort?: number | null,
    title?: string | null,
    image?: string | null,
    active?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnDeleteGIFSubscription = {
  onDeleteGIF?:  {
    __typename: "GIF",
    id: string,
    sort?: number | null,
    title?: string | null,
    image?: string | null,
    active?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnCreateHolidaySubscription = {
  onCreateHoliday?:  {
    __typename: "Holiday",
    id: string,
    title?: string | null,
    sort?: number | null,
    disclaimer?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    yearID: string,
  } | null,
};

export type OnUpdateHolidaySubscription = {
  onUpdateHoliday?:  {
    __typename: "Holiday",
    id: string,
    title?: string | null,
    sort?: number | null,
    disclaimer?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    yearID: string,
  } | null,
};

export type OnDeleteHolidaySubscription = {
  onDeleteHoliday?:  {
    __typename: "Holiday",
    id: string,
    title?: string | null,
    sort?: number | null,
    disclaimer?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    yearID: string,
  } | null,
};

export type OnCreateMemberSubscription = {
  onCreateMember?:  {
    __typename: "Member",
    id: string,
    title?: string | null,
    image?: string | null,
    name: string,
    nameLow?: string | null,
    description?: string | null,
    department?: string | null,
    reportTo?: string | null,
    projects?: Array< string | null > | null,
    phone?: string | null,
    email?: string | null,
    active?: boolean | null,
    startDate?: string | null,
    officeID: string,
    office?:  {
      __typename: "Office",
      id: string,
      name?: string | null,
      sort?: number | null,
      active?: boolean | null,
      group?: string | null,
      timezone?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    sort?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateMemberSubscription = {
  onUpdateMember?:  {
    __typename: "Member",
    id: string,
    title?: string | null,
    image?: string | null,
    name: string,
    nameLow?: string | null,
    description?: string | null,
    department?: string | null,
    reportTo?: string | null,
    projects?: Array< string | null > | null,
    phone?: string | null,
    email?: string | null,
    active?: boolean | null,
    startDate?: string | null,
    officeID: string,
    office?:  {
      __typename: "Office",
      id: string,
      name?: string | null,
      sort?: number | null,
      active?: boolean | null,
      group?: string | null,
      timezone?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    sort?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteMemberSubscription = {
  onDeleteMember?:  {
    __typename: "Member",
    id: string,
    title?: string | null,
    image?: string | null,
    name: string,
    nameLow?: string | null,
    description?: string | null,
    department?: string | null,
    reportTo?: string | null,
    projects?: Array< string | null > | null,
    phone?: string | null,
    email?: string | null,
    active?: boolean | null,
    startDate?: string | null,
    officeID: string,
    office?:  {
      __typename: "Office",
      id: string,
      name?: string | null,
      sort?: number | null,
      active?: boolean | null,
      group?: string | null,
      timezone?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    sort?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateMileageRateSubscription = {
  onCreateMileageRate?:  {
    __typename: "MileageRate",
    id: string,
    rate?: number | null,
    date?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnUpdateMileageRateSubscription = {
  onUpdateMileageRate?:  {
    __typename: "MileageRate",
    id: string,
    rate?: number | null,
    date?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnDeleteMileageRateSubscription = {
  onDeleteMileageRate?:  {
    __typename: "MileageRate",
    id: string,
    rate?: number | null,
    date?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnCreateOfficeSubscription = {
  onCreateOffice?:  {
    __typename: "Office",
    id: string,
    name?: string | null,
    sort?: number | null,
    active?: boolean | null,
    group?: string | null,
    timezone?: string | null,
    members?:  {
      __typename: "ModelMemberConnection",
      nextToken?: string | null,
    } | null,
    pages?:  {
      __typename: "ModelPageConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateOfficeSubscription = {
  onUpdateOffice?:  {
    __typename: "Office",
    id: string,
    name?: string | null,
    sort?: number | null,
    active?: boolean | null,
    group?: string | null,
    timezone?: string | null,
    members?:  {
      __typename: "ModelMemberConnection",
      nextToken?: string | null,
    } | null,
    pages?:  {
      __typename: "ModelPageConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteOfficeSubscription = {
  onDeleteOffice?:  {
    __typename: "Office",
    id: string,
    name?: string | null,
    sort?: number | null,
    active?: boolean | null,
    group?: string | null,
    timezone?: string | null,
    members?:  {
      __typename: "ModelMemberConnection",
      nextToken?: string | null,
    } | null,
    pages?:  {
      __typename: "ModelPageConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateLocationSubscription = {
  onCreateLocation?:  {
    __typename: "Location",
    id: string,
    type: string,
    sort?: number | null,
    active?: boolean | null,
    officeID: string,
    office:  {
      __typename: "Office",
      id: string,
      name?: string | null,
      sort?: number | null,
      active?: boolean | null,
      group?: string | null,
      timezone?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateLocationSubscription = {
  onUpdateLocation?:  {
    __typename: "Location",
    id: string,
    type: string,
    sort?: number | null,
    active?: boolean | null,
    officeID: string,
    office:  {
      __typename: "Office",
      id: string,
      name?: string | null,
      sort?: number | null,
      active?: boolean | null,
      group?: string | null,
      timezone?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteLocationSubscription = {
  onDeleteLocation?:  {
    __typename: "Location",
    id: string,
    type: string,
    sort?: number | null,
    active?: boolean | null,
    officeID: string,
    office:  {
      __typename: "Office",
      id: string,
      name?: string | null,
      sort?: number | null,
      active?: boolean | null,
      group?: string | null,
      timezone?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreatePageSubscription = {
  onCreatePage?:  {
    __typename: "Page",
    id: string,
    label?: string | null,
    status?: PageStatus | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    title: string,
    slug: string,
    description?: string | null,
    eyebrow?: string | null,
    headline?: string | null,
    body?: string | null,
    inlineBody?: string | null,
    ctaText?: string | null,
    ctaLink?: string | null,
    headlineLow?: string | null,
    bodyLow?: string | null,
    layout?: PageLayout | null,
    quicklinks?:  {
      __typename: "ModelQuickLinkConnection",
      nextToken?: string | null,
    } | null,
    referencedQuicklinks?:  {
      __typename: "ModelQuickLinkConnection",
      nextToken?: string | null,
    } | null,
    pageId: string,
    pages?:  {
      __typename: "ModelPageConnection",
      nextToken?: string | null,
    } | null,
    parentPage?:  {
      __typename: "Page",
      id: string,
      label?: string | null,
      status?: PageStatus | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      title: string,
      slug: string,
      description?: string | null,
      eyebrow?: string | null,
      headline?: string | null,
      body?: string | null,
      inlineBody?: string | null,
      ctaText?: string | null,
      ctaLink?: string | null,
      headlineLow?: string | null,
      bodyLow?: string | null,
      layout?: PageLayout | null,
      pageId: string,
      local?: string | null,
      publishedAt?: string | null,
      image?: string | null,
      sort?: number | null,
      meta?: string | null,
      officeId?: string | null,
    } | null,
    local?: string | null,
    publishedAt?: string | null,
    image?: string | null,
    sort?: number | null,
    meta?: string | null,
    officeId?: string | null,
    office?:  {
      __typename: "Office",
      id: string,
      name?: string | null,
      sort?: number | null,
      active?: boolean | null,
      group?: string | null,
      timezone?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type OnUpdatePageSubscription = {
  onUpdatePage?:  {
    __typename: "Page",
    id: string,
    label?: string | null,
    status?: PageStatus | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    title: string,
    slug: string,
    description?: string | null,
    eyebrow?: string | null,
    headline?: string | null,
    body?: string | null,
    inlineBody?: string | null,
    ctaText?: string | null,
    ctaLink?: string | null,
    headlineLow?: string | null,
    bodyLow?: string | null,
    layout?: PageLayout | null,
    quicklinks?:  {
      __typename: "ModelQuickLinkConnection",
      nextToken?: string | null,
    } | null,
    referencedQuicklinks?:  {
      __typename: "ModelQuickLinkConnection",
      nextToken?: string | null,
    } | null,
    pageId: string,
    pages?:  {
      __typename: "ModelPageConnection",
      nextToken?: string | null,
    } | null,
    parentPage?:  {
      __typename: "Page",
      id: string,
      label?: string | null,
      status?: PageStatus | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      title: string,
      slug: string,
      description?: string | null,
      eyebrow?: string | null,
      headline?: string | null,
      body?: string | null,
      inlineBody?: string | null,
      ctaText?: string | null,
      ctaLink?: string | null,
      headlineLow?: string | null,
      bodyLow?: string | null,
      layout?: PageLayout | null,
      pageId: string,
      local?: string | null,
      publishedAt?: string | null,
      image?: string | null,
      sort?: number | null,
      meta?: string | null,
      officeId?: string | null,
    } | null,
    local?: string | null,
    publishedAt?: string | null,
    image?: string | null,
    sort?: number | null,
    meta?: string | null,
    officeId?: string | null,
    office?:  {
      __typename: "Office",
      id: string,
      name?: string | null,
      sort?: number | null,
      active?: boolean | null,
      group?: string | null,
      timezone?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type OnDeletePageSubscription = {
  onDeletePage?:  {
    __typename: "Page",
    id: string,
    label?: string | null,
    status?: PageStatus | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    title: string,
    slug: string,
    description?: string | null,
    eyebrow?: string | null,
    headline?: string | null,
    body?: string | null,
    inlineBody?: string | null,
    ctaText?: string | null,
    ctaLink?: string | null,
    headlineLow?: string | null,
    bodyLow?: string | null,
    layout?: PageLayout | null,
    quicklinks?:  {
      __typename: "ModelQuickLinkConnection",
      nextToken?: string | null,
    } | null,
    referencedQuicklinks?:  {
      __typename: "ModelQuickLinkConnection",
      nextToken?: string | null,
    } | null,
    pageId: string,
    pages?:  {
      __typename: "ModelPageConnection",
      nextToken?: string | null,
    } | null,
    parentPage?:  {
      __typename: "Page",
      id: string,
      label?: string | null,
      status?: PageStatus | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      title: string,
      slug: string,
      description?: string | null,
      eyebrow?: string | null,
      headline?: string | null,
      body?: string | null,
      inlineBody?: string | null,
      ctaText?: string | null,
      ctaLink?: string | null,
      headlineLow?: string | null,
      bodyLow?: string | null,
      layout?: PageLayout | null,
      pageId: string,
      local?: string | null,
      publishedAt?: string | null,
      image?: string | null,
      sort?: number | null,
      meta?: string | null,
      officeId?: string | null,
    } | null,
    local?: string | null,
    publishedAt?: string | null,
    image?: string | null,
    sort?: number | null,
    meta?: string | null,
    officeId?: string | null,
    office?:  {
      __typename: "Office",
      id: string,
      name?: string | null,
      sort?: number | null,
      active?: boolean | null,
      group?: string | null,
      timezone?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type OnCreatePolicySubscription = {
  onCreatePolicy?:  {
    __typename: "Policy",
    id: string,
    location?: AllLocation | null,
    title?: string | null,
    acknoledgement?: boolean | null,
    version?: string | null,
    content?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdatePolicySubscription = {
  onUpdatePolicy?:  {
    __typename: "Policy",
    id: string,
    location?: AllLocation | null,
    title?: string | null,
    acknoledgement?: boolean | null,
    version?: string | null,
    content?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeletePolicySubscription = {
  onDeletePolicy?:  {
    __typename: "Policy",
    id: string,
    location?: AllLocation | null,
    title?: string | null,
    acknoledgement?: boolean | null,
    version?: string | null,
    content?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateQuickLinkSubscription = {
  onCreateQuickLink?:  {
    __typename: "QuickLink",
    id: string,
    pageId: string,
    page?:  {
      __typename: "Page",
      id: string,
      label?: string | null,
      status?: PageStatus | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      title: string,
      slug: string,
      description?: string | null,
      eyebrow?: string | null,
      headline?: string | null,
      body?: string | null,
      inlineBody?: string | null,
      ctaText?: string | null,
      ctaLink?: string | null,
      headlineLow?: string | null,
      bodyLow?: string | null,
      layout?: PageLayout | null,
      pageId: string,
      local?: string | null,
      publishedAt?: string | null,
      image?: string | null,
      sort?: number | null,
      meta?: string | null,
      officeId?: string | null,
    } | null,
    sort?: number | null,
    title?: string | null,
    body?: string | null,
    linkTitle?: string | null,
    externalLink?: string | null,
    linkId?: string | null,
    link?:  {
      __typename: "Page",
      id: string,
      label?: string | null,
      status?: PageStatus | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      title: string,
      slug: string,
      description?: string | null,
      eyebrow?: string | null,
      headline?: string | null,
      body?: string | null,
      inlineBody?: string | null,
      ctaText?: string | null,
      ctaLink?: string | null,
      headlineLow?: string | null,
      bodyLow?: string | null,
      layout?: PageLayout | null,
      pageId: string,
      local?: string | null,
      publishedAt?: string | null,
      image?: string | null,
      sort?: number | null,
      meta?: string | null,
      officeId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateQuickLinkSubscription = {
  onUpdateQuickLink?:  {
    __typename: "QuickLink",
    id: string,
    pageId: string,
    page?:  {
      __typename: "Page",
      id: string,
      label?: string | null,
      status?: PageStatus | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      title: string,
      slug: string,
      description?: string | null,
      eyebrow?: string | null,
      headline?: string | null,
      body?: string | null,
      inlineBody?: string | null,
      ctaText?: string | null,
      ctaLink?: string | null,
      headlineLow?: string | null,
      bodyLow?: string | null,
      layout?: PageLayout | null,
      pageId: string,
      local?: string | null,
      publishedAt?: string | null,
      image?: string | null,
      sort?: number | null,
      meta?: string | null,
      officeId?: string | null,
    } | null,
    sort?: number | null,
    title?: string | null,
    body?: string | null,
    linkTitle?: string | null,
    externalLink?: string | null,
    linkId?: string | null,
    link?:  {
      __typename: "Page",
      id: string,
      label?: string | null,
      status?: PageStatus | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      title: string,
      slug: string,
      description?: string | null,
      eyebrow?: string | null,
      headline?: string | null,
      body?: string | null,
      inlineBody?: string | null,
      ctaText?: string | null,
      ctaLink?: string | null,
      headlineLow?: string | null,
      bodyLow?: string | null,
      layout?: PageLayout | null,
      pageId: string,
      local?: string | null,
      publishedAt?: string | null,
      image?: string | null,
      sort?: number | null,
      meta?: string | null,
      officeId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteQuickLinkSubscription = {
  onDeleteQuickLink?:  {
    __typename: "QuickLink",
    id: string,
    pageId: string,
    page?:  {
      __typename: "Page",
      id: string,
      label?: string | null,
      status?: PageStatus | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      title: string,
      slug: string,
      description?: string | null,
      eyebrow?: string | null,
      headline?: string | null,
      body?: string | null,
      inlineBody?: string | null,
      ctaText?: string | null,
      ctaLink?: string | null,
      headlineLow?: string | null,
      bodyLow?: string | null,
      layout?: PageLayout | null,
      pageId: string,
      local?: string | null,
      publishedAt?: string | null,
      image?: string | null,
      sort?: number | null,
      meta?: string | null,
      officeId?: string | null,
    } | null,
    sort?: number | null,
    title?: string | null,
    body?: string | null,
    linkTitle?: string | null,
    externalLink?: string | null,
    linkId?: string | null,
    link?:  {
      __typename: "Page",
      id: string,
      label?: string | null,
      status?: PageStatus | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      title: string,
      slug: string,
      description?: string | null,
      eyebrow?: string | null,
      headline?: string | null,
      body?: string | null,
      inlineBody?: string | null,
      ctaText?: string | null,
      ctaLink?: string | null,
      headlineLow?: string | null,
      bodyLow?: string | null,
      layout?: PageLayout | null,
      pageId: string,
      local?: string | null,
      publishedAt?: string | null,
      image?: string | null,
      sort?: number | null,
      meta?: string | null,
      officeId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateYearSubscription = {
  onCreateYear?:  {
    __typename: "Year",
    id: string,
    title?: string | null,
    sort?: number | null,
    date?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    holidays?:  {
      __typename: "ModelHolidayConnection",
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateYearSubscription = {
  onUpdateYear?:  {
    __typename: "Year",
    id: string,
    title?: string | null,
    sort?: number | null,
    date?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    holidays?:  {
      __typename: "ModelHolidayConnection",
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteYearSubscription = {
  onDeleteYear?:  {
    __typename: "Year",
    id: string,
    title?: string | null,
    sort?: number | null,
    date?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    holidays?:  {
      __typename: "ModelHolidayConnection",
      nextToken?: string | null,
    } | null,
  } | null,
};
