import React, { useCallback, useMemo } from 'react';
import {
  SubmitButton,
  CancelButton,
  ButtonContainer,
  ModalContainer,
} from './styles';
import * as mutations from 'graphql/mutations';
import { useMutation, gql } from '@apollo/client';
import IToast from 'types/ToastType';
import { debounce } from 'lodash';
import { SectionDataI } from 'containers/FAQsContainer';

export const DELETE_FAQ = gql(mutations.deleteFAQ);
export const DELETE_FAQSECTION = gql(mutations.deleteFAQsection);
export const DELETE_FREELANCER = gql(mutations.deleteFreelancer);
export const DELETE_GIF = gql(mutations.deleteGIF);
export const DELETE_HOLIDAY = gql(mutations.deleteHoliday);
export const DELETE_LOCATION = gql(mutations.deleteLocation);
export const DELETE_MEMBER = gql(mutations.deleteMember);
export const DELETE_OFFICE = gql(mutations.deleteOffice);
export const DELETE_PAGE = gql(mutations.deletePage);
export const DELETE_QUICKLINK = gql(mutations.deleteQuickLink);
export const DELETE_YEAR = gql(mutations.deleteYear);
export const DELETE_MILEAGERATE = gql(mutations.deleteMileageRate);

interface IDeleteFormProps {
  toggle: (refetch?: boolean) => void;
  toggleDelete?: () => void;
  onPhraseDelete?: () => void;
  toast: IToast;
  type: string;
  id: string;
  pageIDs?: string[];
  officeID?: string;
  preventUnlock?: boolean;
  deactivate?: boolean;
  sectionData?: SectionDataI[];
}

const DeleteForm = ({
  toggle,
  toggleDelete,
  onPhraseDelete,
  toast,
  type,
  id,
  officeID,
  pageIDs,
  preventUnlock,
  deactivate,
  sectionData,
}: IDeleteFormProps): JSX.Element => {
  // Toast handler
  const toastHandler = useCallback(
    (toastType: string, toastMessage: string) => {
      switch (toastType) {
        case 'success':
          toast.success(toastMessage);
          break;
        case 'error':
          toast.error(toastMessage);
          break;
        default:
          break;
      }
    },
    [toast]
  );

  // Make sure toast calls only happen every 0.5 seconds to avoid duplicate messages
  const debouncedToast = useMemo(
    () =>
      debounce((toastType: string, toastMessage: string) => {
        toastHandler(toastType, toastMessage);
      }, 500),
    [toastHandler]
  );

  const [deleteFAQ] = useMutation(DELETE_FAQ, {
    onCompleted: () => {
      debouncedToast('success', 'FAQ deleted');
    },
    onError: () => {
      debouncedToast('error', 'Error deleting FAQ');
    },
  });
  const [deleteFAQSection] = useMutation(DELETE_FAQSECTION, {
    onCompleted: () => {
      debouncedToast('success', 'FAQ Section deleted');
    },
    onError: () => {
      debouncedToast('error', 'Error deleting FAQ Section');
    },
  });
  const [deleteFreelancer] = useMutation(DELETE_FREELANCER, {
    onCompleted: () => {
      debouncedToast('success', 'Freelancer deleted');
    },
    onError: () => {
      debouncedToast('error', 'Error deleting Freelancer');
    },
  });
  const [deleteGIF] = useMutation(DELETE_GIF, {
    onCompleted: () => {
      debouncedToast('success', 'GIF deleted');
    },
    onError: () => {
      debouncedToast('error', 'Error deleting GIF');
    },
  });
  const [deleteHoliday] = useMutation(DELETE_HOLIDAY, {
    onCompleted: () => {
      debouncedToast('success', 'Holiday deleted');
    },
    onError: () => {
      debouncedToast('error', 'Error deleting Holiday');
    },
  });
  const [deleteLocation] = useMutation(DELETE_LOCATION, {
    onCompleted: () => {
      debouncedToast('success', 'Location deleted');
    },
    onError: () => {
      debouncedToast('error', 'Error deleting Location');
    },
  });
  const [deleteMember] = useMutation(DELETE_MEMBER, {
    onCompleted: () => {
      debouncedToast('success', 'Member deleted');
    },
    onError: () => {
      debouncedToast('error', 'Error deleting Member');
    },
  });
  const [deleteOffice] = useMutation(DELETE_OFFICE, {
    onCompleted: () => {
      // debouncedToast('success', 'Office deleted');
    },
    onError: () => {
      debouncedToast('error', 'Error deleting Office');
    },
  });
  const [deletePage] = useMutation(DELETE_PAGE, {
    onCompleted: () => {
      debouncedToast('success', 'Page deleted');
    },
    onError: () => {
      debouncedToast('error', 'Error deleting Page');
    },
  });
  const [deleteTab] = useMutation(DELETE_PAGE, {
    onCompleted: () => {
      debouncedToast('success', 'Tab deleted');
    },
    onError: () => {
      debouncedToast('error', 'Error deleting Tab');
    },
  });
  const [deleteQuicklink] = useMutation(DELETE_QUICKLINK, {
    onCompleted: () => {
      debouncedToast('success', 'Quick Link deleted');
    },
    onError: () => {
      debouncedToast('error', 'Error deleting Quick Link');
    },
  });
  const [deleteYear] = useMutation(DELETE_YEAR, {
    onCompleted: () => {
      debouncedToast('success', 'Year deleted');
    },
    onError: () => {
      debouncedToast('error', 'Error deleting Year');
    },
  });
  const [deleteMileageRate] = useMutation(DELETE_MILEAGERATE, {
    onCompleted: () => {
      debouncedToast('success', 'MileageRate deleted');
    },
    onError: () => {
      debouncedToast('error', 'Error deleting MileageRate');
    },
  });

  const deleteFunc = async (variables: {
    variables: { input: { id: string } };
  }) => {
    if (type === 'faq') {
      await deleteFAQ(variables);
    } else if (type === 'faqSection') {
      await deleteFAQSection(variables);
    } else if (type === 'freelancer') {
      await deleteFreelancer(variables);
    } else if (type === 'gif') {
      await deleteGIF(variables);
    } else if (type === 'holiday') {
      await deleteHoliday(variables);
    } else if (type === 'member') {
      await deleteMember(variables);
    } else if (type === 'office') {
      await deleteLocation(variables);
      if (officeID) {
        await deleteOffice({ variables: { input: { id: officeID } } });
      }
    } else if (type === 'page' || type === 'section') {
      await deletePage(variables);
    } else if (type === 'tab') {
      await deleteTab(variables);
    } else if (type === 'quicklink') {
      await deleteQuicklink(variables);
    } else if (type === 'phrase') {
      if (toggleDelete) {
        toggleDelete();
      }
    } else if (type === 'year') {
      await deleteYear(variables);
    } else if (type === 'mileageRate') {
      await deleteMileageRate(variables);
    }
  };

  const deleteItem = (): string => {
    switch (type) {
      case 'faq':
        return 'this question';
      case 'faqSection':
        if (sectionData) {
          const thisSection = sectionData.find((section) => section.id == id);
          const title = thisSection?.title;

          if (title) {
            return `the ${title} Category`;
          }
        }

        return 'this section';
      case 'gif':
        return 'this GIF';
      case 'quicklink':
        return 'this quick link';
      case 'search':
        return 'this suggestion';
      case 'mileageRateFake':
      case 'mileageRate':
        return 'this mileage rate';
      default:
        return type;
    }
  };

  return (
    <ModalContainer
      id="sectionModal"
      title={`Are you sure you want to ${
        deactivate ? 'DEACTIVATE' : 'DELETE'
      } ${deleteItem()}?`}
      description="Warning: This cannot be undone"
      toggle={() => {
        if (toggleDelete) {
          toggleDelete();
        } else {
          toggle();
        }
      }}
      preventUnlock={preventUnlock}
    >
      <ButtonContainer>
        <SubmitButton
          type="button"
          className="ModalSubmit"
          delete
          onClick={async () => {
            if (type === 'phrase') {
              toggle(true);
              if (onPhraseDelete) {
                onPhraseDelete();
              }
            } else if (type !== 'mileageRateFake') {
              await deleteFunc({ variables: { input: { id } } });
              if (pageIDs) {
                for (let i = 0; i < pageIDs.length; i++) {
                  const pageID = pageIDs[i];
                  await deletePage({
                    variables: {
                      input: { id: pageID },
                    },
                  });
                }
              }
            }
            toggle(true);
          }}
        >
          {deactivate ? 'Yes, Deactivate' : 'Yes, Delete'}
        </SubmitButton>
        <CancelButton
          type="button"
          className="Modalcancel"
          value="Cancel"
          tertiary
          onClick={() => {
            if (toggleDelete) {
              toggleDelete();
            } else {
              toggle();
            }
          }}
        >
          Cancel
        </CancelButton>
      </ButtonContainer>
    </ModalContainer>
  );
};

export default DeleteForm;
