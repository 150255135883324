import React, { useEffect, useMemo, useRef, useState } from 'react';
import { getPage, listMileageRates } from 'graphql/queries';
import * as mutations from 'graphql/mutations';
import { useQuery, gql, useMutation } from '@apollo/client';
import { AddButton, Container, Form } from './styles';
import PageTop from 'components/PageTop';
import { listPages } from 'utils/queries';
import { SubmitHandler, useForm } from 'react-hook-form';
import PageTopTypes from 'components/PageTop/PageTop.types';
import { cleanString, getSlugs, postWYSIWYG } from 'utils/functions';
import SectionContainer from 'components/SectionContainer';
import DeleteForm from 'components/Forms/DeleteForm/DeleteForm';
import { MileageRate } from 'API';
import dayjs from 'dayjs';
import RateRow from './Components/RateRow';
import { IconAdd } from 'components/IconsView';
import { SlugI } from 'types/SlugListType';
import LoaderView from 'components/LoaderView';

export const GET_PAGE = gql(getPage);
export const LIST_PAGES = gql(listPages);
export const LIST_MILEAGERATES = gql(listMileageRates);
export const UPDATE_PAGE = gql(mutations.updatePage);
export const UPDATE_MILEAGERATE = gql(mutations.updateMileageRate);
export const CREATE_MILEAGERATE = gql(mutations.createMileageRate);
interface IProps {
  toast: {
    success: (str: string) => void;
    error: (str: string) => void;
  };
  id: string;
}

export interface MileageItem {
  id?: string;
  rate?: number;
  date?: string;
}

const ExpenseReport = ({ toast, id }: IProps): JSX.Element => {
  const [deleteMileageRateID, setDeleteMileageRateID] = useState('');
  const [deleteShow, setDeleteShow] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(0);
  const pageFormRef = useRef<HTMLFormElement>(null);
  const [isDirtyForm, setIsDirtyForm] = useState(false);
  const [mileageRateIsDirty, setMileageRateIsDirty] = useState(false);
  const inputFormRef = useRef<HTMLFormElement>(null);
  const [updateToastShow, setUpdateToastShow] = useState(false);
  const [createToastShow, setCreateToastShow] = useState(false);
  const [mileageList, setMileageList] = useState<MileageItem[]>([]);
  const [fakeDeleteShow, setFakeDeleteShow] = useState<number | null>(null);
  const {
    loading,
    refetch,
    data: pageData,
  } = useQuery(GET_PAGE, {
    variables: {
      id: id,
    },
    fetchPolicy: 'network-only',
  });
  // For meta to check against slug
  const { data: mileageRateData, refetch: mileageRateRefetch } =
    useQuery(LIST_MILEAGERATES);

  useEffect(() => {
    setMileageList(
      mileageRateData?.listMileageRates?.items
        ?.slice()
        .sort((a: MileageRate, b: MileageRate) => {
          if (!a.date) {
            return 1;
          }
          if (!b.date) {
            return -1;
          }
          return Date.parse(b.date) - Date.parse(a.date);
        })
        .map((m: MileageRate) => {
          return {
            id: m.id,
            date: m.date,
            rate: m.rate,
          };
        }) || []
    );
  }, [mileageRateData]);

  const [slugList, setSlugs] = useState<SlugI[]>([]);
  const doFirst = async () => {
    const temp = await getSlugs();
    setSlugs(temp);
  };
  useEffect(() => {
    doFirst();
  }, []);

  const methods = useForm();

  const data = useMemo(() => {
    return pageData?.getPage;
  }, [pageData]);

  useEffect(() => {
    setMileageRateIsDirty(methods.formState.isDirty);
  }, [methods.formState.isDirty]);

  const [updatePage] = useMutation(UPDATE_PAGE, {
    onCompleted: () => {
      toast.success('Page updated');
      refetch();
    },
    onError: () => {
      toast.error('Error updating page');
    },
  });

  const [createMileageRate] = useMutation(CREATE_MILEAGERATE, {
    onError: () => {
      if (!createToastShow) {
        toast.error('Error creating mileage rate');
        setCreateToastShow(true);
      }
    },
  });

  const [updateMileageRate] = useMutation(UPDATE_MILEAGERATE, {
    onError: () => {
      if (!updateToastShow) {
        toast.error('Error updating mileage rate');
        setUpdateToastShow(true);
      }
    },
  });

  const onSubmitUpdatePage: SubmitHandler<PageTopTypes> = (props) => {
    // remove aws wysiwyg img src and leave behind only keys
    const shortBody = postWYSIWYG(props.body);
    const cleanShortBody = cleanString(shortBody);

    updatePage({
      variables: {
        input: {
          id,
          ...props,
          updatedAt: new Date().toISOString(),
          label: props.title,
          headlineLow: props?.headline && props?.headline.toLowerCase(),
          body: shortBody,
          bodyLow: cleanShortBody.toLowerCase(),
        },
      },
    });
  };

  const inputOnSubmit: SubmitHandler<PageTopTypes> = async (props) => {
    console.log('ENTERED', props);
    const row = props.row;
    setUpdateToastShow(false);
    setCreateToastShow(false);
    setMileageRateIsDirty(false);
    methods.reset();
    for (let i = 0; i < props.row.length; i++) {
      const obj = props.row[i];
      if (obj.id) {
        await updateMileageRate({
          variables: {
            input: {
              id: obj.id,
              date: dayjs(obj.date).toISOString(),
              rate: obj.rate,
            },
          },
        });
      } else {
        const mileage = await createMileageRate({
          variables: {
            input: {
              date: dayjs(obj.date).toISOString(),
              rate: obj.rate,
            },
          },
        });
        row[i].id = mileage.data.createMileageRate.id;
      }
    }

    toast.success('Mileage Rates Updated');
    methods.setValue(
      'row',
      row.sort((a: any, b: any) => Date.parse(b.date) - Date.parse(a.date))
    );
    mileageRateRefetch();
  };

  const submitAllForms = () => {
    // trigger submit function from refs
    if (pageFormRef.current) {
      pageFormRef.current.dispatchEvent(
        new Event('submit', { bubbles: true, cancelable: true })
      );
    }
    if (inputFormRef.current) {
      inputFormRef.current.dispatchEvent(
        new Event('submit', { bubbles: true, cancelable: true })
      );
    }
  };

  const handleTrash = (deleteID: string, index: number) => {
    if (deleteID) {
      if (setDeleteMileageRateID) {
        setDeleteMileageRateID(deleteID);
      }
      if (setDeleteShow) {
        setDeleteShow(true);
      }
      if (setDeleteIndex) {
        setDeleteIndex(index);
      }
    } else {
      if (setFakeDeleteShow) {
        setFakeDeleteShow(index);
      }
    }
    methods.reset();
  };

  const handleEmptyTrash = (index: number) => {
    const newList = mileageList?.filter((_: any, i: any) => i !== index);
    setMileageList(newList);
    if (setMileageRateIsDirty) {
      setMileageRateIsDirty(false);
    }
    methods.reset();
  };

  if (loading) {
    return <LoaderView />;
  }

  return (
    <Container>
      <PageTop
        ref={pageFormRef}
        submitAllForms={submitAllForms}
        onSubmitUpdatePage={onSubmitUpdatePage}
        slugList={slugList}
        data={data}
        setIsDirtyForm={setIsDirtyForm}
        isDirtyForm={isDirtyForm}
        mileageRateIsDirty={mileageRateIsDirty}
      />
      <SectionContainer
        title="Set Mileage Rates"
        description="Mileage rates set here will be accounted for when an employee is expensing mileage."
      >
        <Form
          noValidate
          onSubmit={methods.handleSubmit(inputOnSubmit)}
          ref={inputFormRef}
        >
          {mileageList
            ?.map((mileage: MileageItem, m: number) => {
              return (
                <RateRow
                  key={mileage.id || m}
                  index={m}
                  methods={methods}
                  moreThanOne={mileageList.length > 1}
                  handleTrash={handleTrash}
                  handleEmptyTrash={handleEmptyTrash}
                  zIndex={mileageList.length - m}
                  defaultValues={mileage}
                  mileageList={mileageList}
                />
              );
            })
            .sort()}
          <AddButton
            type="button"
            tertiary
            aria-label="Add Another Rate"
            onClick={() => {
              if (setMileageRateIsDirty) {
                setMileageRateIsDirty(true);
              }
              setMileageList((prev) => [...prev, {}]);
            }}
          >
            <IconAdd /> Add Another Rate
          </AddButton>
          {fakeDeleteShow && (
            <DeleteForm
              type="mileageRateFake"
              id={'fake'}
              toggle={() => {
                setFakeDeleteShow(null);
                const temp = methods.watch().row;
                temp.splice(fakeDeleteShow, 1);
                methods.setValue('row', temp);
                methods.unregister(`row[${temp.length - 1}]`);
                const newList = mileageList?.filter(
                  (_: any, i: any) => i !== fakeDeleteShow
                );
                setMileageList(newList);
              }}
              toast={toast}
            />
          )}
        </Form>
      </SectionContainer>
      {deleteShow && (
        <DeleteForm
          type="mileageRate"
          id={deleteMileageRateID}
          toggle={() => {
            setDeleteShow(!deleteShow);
            mileageRateRefetch();
            const temp = methods.watch().row;
            temp.splice(deleteIndex, 1);
            methods.setValue('row', temp);
            methods.unregister(`row[${temp.length - 1}]`);
          }}
          toast={toast}
        />
      )}
    </Container>
  );
};

export default ExpenseReport;
