import React, {
  FC,
  useState,
  useCallback,
  useMemo,
  useRef,
  useEffect,
} from 'react';
import { useQuery, gql, useLazyQuery, useMutation } from '@apollo/client';
import { getPage } from 'graphql/queries';
import { withFeature } from 'flagged';
import * as queries from './queries';
import * as mutations from 'graphql/mutations';
import * as globalQueries from 'graphql/queries';
import Accordion from 'components/Accordion';
import AccordionTypes from 'components/Accordion/Accordion.types';
import { formatDataForAccordion } from './helpers/format';
import SectionForm from './Forms/SectionForm/SectionForm';
import QuestionForm from './Forms/QuestionForm/QuestionForm';
import PageTop from 'components/PageTop';
import { IFAQ } from './FAQsContainer.types';
import { listPages } from 'utils/queries';
import DeleteForm from 'components/Forms/DeleteForm/DeleteForm';
import SectionContainer from 'components/SectionContainer';
import MigrateForm from 'components/Forms/MigrateForm/MigrateForm';
import { SubmitHandler } from 'react-hook-form';
import PageTopTypes from 'components/PageTop/PageTop.types';
import { cleanString, getSlugs, postWYSIWYG } from 'utils/functions';
import AccordionItems from 'components/Accordion/AccordionItem/AccordionItem.types';
import { arrayMove } from 'react-sortable-hoc';

import { Container } from './styles';
import { FAQ, FAQsection } from 'API';
import { ISortingParent } from 'types/SortingType';
import { SlugI } from 'types/SlugListType';
import LoaderView from 'components/LoaderView';

export const GET_PAGE = gql(getPage);
export const LIST_PAGES = gql(listPages);
const GET_FAQ = gql(globalQueries.getFAQ);
const GET_FAQ_SECTION = gql(queries.getFAQsection);
const LIST_FAQ_SECTIONS = gql(queries.listFAQSections);
const LIST_OFFICES = gql(globalQueries.listOffices);
const UPDATE_PAGE = gql(mutations.updatePage);
const SORT_ITEMS = gql(mutations.sortItemsV2);
const UPDATE_FAQ = gql(mutations.updateFAQ);
const DELETE_FAQ_SECTION = gql(mutations.deleteFAQsection);

export interface FAQDataI {
  id: string;
  sort: number;
  answer: string;
  officeIDs?: string[];
  question: string;
}
export interface SectionDataI {
  id: string;
  sort: number;
  title: string;
  createdAt: string;
  updatedAt: string;
  faqs: FAQDataI[];
}

const FAQsContainer: FC<IFAQ> = ({ toast }) => {
  const [accordionsSort, setAccordionsSort] = useState<ISortingParent[]>([]);
  const [itemSorts, setItemSorts] = useState<{
    [key: string]: AccordionItems[];
  }>({});
  const pageFormRef = useRef<HTMLFormElement>(null);
  const [updatingSection, setUpdatingSection] = useState<{
    defaultValues: FAQsection;
  } | null>(null);
  const [creatingSection, setCreatingSection] = useState(false);
  const [addingQuestion, setAddingQuestion] = useState<{
    id: string;
    length: number;
  } | null>(null);
  const [updatingQuestion, setUpdatingQuestion] = useState<{
    defaultValues: FAQ;
  } | null>(null);
  const [deleteForm, setDeleteForm] = useState<{
    id: string;
    type: string;
  } | null>(null);
  const [deleteFormMigrate, setDeleteFormMigrate] = useState<{
    id: string;
  } | null>(null);
  const [isDirtyForm, setIsDirtyForm] = useState(false);
  const [isDirtyPageTop, setIsDirtyPageTop] = useState(false);
  const [accordionIsOpen, setAccordionIsOpen] = useState<any[]>([]);
  const [openSection, setOpenSection] = useState(false);
  const [sectionToDelete, setSectionToDelete] = useState<any>(null);

  const [slugList, setSlugs] = useState<SlugI[]>([]);
  const doFirst = async () => {
    const temp = await getSlugs();
    setSlugs(temp);
  };
  useEffect(() => {
    doFirst();
  }, []);

  // GET FAQs PAGE DATA
  const {
    loading: pageLoading,
    refetch: pageRefetch,
    data: pageData,
  } = useQuery(GET_PAGE, {
    variables: {
      id: 'page-faq',
    },
  });

  const { data: officeData } = useQuery(LIST_OFFICES, {
    fetchPolicy: 'network-only',
  });

  const pageDataMemo = useMemo(() => {
    return pageData?.getPage;
  }, [pageData]);

  const offices = useMemo(() => {
    return officeData?.listOffices?.items;
  }, [officeData]);

  //* mutations
  const [itemSort] = useMutation(SORT_ITEMS, {
    onCompleted: () => {
      toast.success('FAQs sorted');
    },
    onError: () => {
      toast.error('Error sorting FAQs');
    },
  });
  const [parentSort] = useMutation(SORT_ITEMS, {
    onCompleted: () => {
      toast.success('Categories sorted');
    },
    onError: () => {
      toast.error('Error sorting categories');
    },
  });

  useEffect(() => {
    // check if items were sorted or changed views
    if (Object.keys(itemSorts).length > 0) {
      setIsDirtyForm(true);
    } else {
      // if not then check if PageTop is dirty too
      if (!isDirtyPageTop) {
        // if not then it was just the accordion that was dirty so update isDirty to false now
        setIsDirtyForm(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemSorts]);

  const [updatePage] = useMutation(UPDATE_PAGE, {
    onCompleted: () => {
      toast.success('Page updated');
      pageRefetch();
    },
    onError: () => {
      toast.error('Error updating page');
    },
  });

  const [updateFAQ] = useMutation(UPDATE_FAQ, {
    onCompleted: () => {
      toast.success('Question updated');
    },
    onError: () => {
      toast.error('Error updating question');
    },
  });

  // GET FAQ SECTIONS AND ASSOCIATED FAQS
  const [sectionData, setSectionData] = useState<SectionDataI[]>([]);
  const { data: faqSections, loading, refetch } = useQuery(LIST_FAQ_SECTIONS);

  // HANDLE FAQ SECTION DATA BEING FETCHED
  useEffect(() => {
    // SANITY CHECK
    if (loading && !faqSections) {
      return;
    }

    // GET SECTION AND FAQ FORMATTED DATA
    const formattedData = faqSections.listFAQsections.items.map(
      ({ id, sort, title, createdAt, updatedAt, faqs }: FAQsection) => {
        const formattedFaqs = faqs?.items?.map(
          ({ id: faqID, answer, officeIDs, question, sort: faqSort }) => ({
            id: faqID,
            answer,
            officeIDs,
            question,
            sort: faqSort,
          })
        );

        return {
          id,
          sort,
          title,
          createdAt,
          updatedAt,
          faqs: formattedFaqs,
        };
      }
    );

    // SET SECTION AND FAQ FORMATTED DATA
    setSectionData(formattedData);
  }, [faqSections, loading]);

  const [getFAQ] = useLazyQuery(GET_FAQ, {
    onCompleted: ({ getFAQ: faq }) => {
      setUpdatingQuestion({ defaultValues: faq });
    },
    onError: () => {
      toast.error('There was an error getting FAQ');
    },
  });

  const [getSection] = useLazyQuery(GET_FAQ_SECTION, {
    onCompleted: ({ getFAQsection: section }) => {
      if (openSection) {
        setUpdatingSection({ defaultValues: section });
      }
    },
    onError: () => {
      toast.error('There was an error getting FAQ section');
    },
  });

  const [deleteFAQSection] = useMutation(DELETE_FAQ_SECTION, {
    onCompleted: () => {
      toast.success('FAQ Section deleted');
    },
    onError: () => {
      toast.error('Error deleting FAQ Section');
    },
  });

  const editQuestion = useCallback(
    (id: string) => {
      getFAQ({ variables: { id } });
    },
    [getFAQ]
  );

  const editSection = useCallback(
    (id: string) => {
      setOpenSection(true);
      getSection({ variables: { id } });
    },
    [getSection]
  );

  useEffect(() => {
    const fetchSection = async () => {
      if (deleteFormMigrate) {
        const { id } = deleteFormMigrate;
        const section = await getSection({ variables: { id } });
        if (section) {
          setSectionToDelete(section.data?.getFAQsection);
        }
      }
    };
    fetchSection();
  }, [deleteFormMigrate, getSection]);

  const sortItems = useCallback(
    async (
      { oldIndex, newIndex }: { oldIndex: number; newIndex: number },
      items?: any[],
      id?: string,
      parentIndex?: number
    ) => {
      const newSort = { ...itemSorts };
      if (id && items) {
        /* Checking to see if the array has been sorted. If it has, it will add it to the pageSorts
        object. If it hasn't, it will delete it from the pageSorts object. */
        const probableChange = arrayMove(items, oldIndex, newIndex);
        const differenceAry = probableChange.slice(1).map(function (n, i) {
          return n.index - probableChange[i].index;
        });
        const isDifference = differenceAry.some((value) => value !== 0);
        if (isDifference) {
          newSort[id] = probableChange;
        } else {
          delete newSort[id];
        }
      }
      setItemSorts(newSort);

      if (id === 'sections') {
        setAccordionsSort((prev) => {
          const accordionChange = arrayMove(prev, oldIndex, newIndex);
          return accordionChange;
        });
      } else {
        if (parentIndex?.toString) {
          setAccordionsSort((prev) => {
            const accordionChange = JSON.parse(JSON.stringify(prev));
            const section = accordionChange[parentIndex]?.items || [];
            const changedSection = arrayMove(section, oldIndex, newIndex);

            // Update Sort field not handled by array-move
            const changedSectionSortUpdated = changedSection.map(
              (sec: any, index: number) => {
                sec.sort = index;
                return sec;
              }
            );
            accordionChange[parentIndex].items = changedSectionSortUpdated;
            return accordionChange;
          });
        }
      }
    },
    [itemSorts]
  );

  useEffect(() => {
    // create the sorting object state (Initial State)
    const sortingParent: ISortingParent[] = [];
    sectionData
      .slice()
      ?.sort((x, y) => {
        return (x.sort || 0) - (y.sort || 0);
      })
      .map((section) => {
        const sections: ISortingParent = { id: section.id, items: [] };
        // SECTION - FAQ SORTING
        section.faqs
          .slice()
          ?.sort((x, y) => {
            return (x.sort || 0) - (y.sort || 0);
          })
          .map((faq) => {
            sections.items?.push(faq);
          });
        sortingParent.push(sections);
      });
    setAccordionsSort(sortingParent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionData]);

  const accordionData: AccordionTypes | null = useMemo(() => {
    if (!sectionData) return null;

    return formatDataForAccordion({
      sectionData,
      accordionsSort,
      accordionIsOpen: accordionIsOpen,
      offices,
      addQuestion: setAddingQuestion,
      editQuestion,
      addSection: setCreatingSection,
      editSection,
      sortItems,
      setDeleteForm,
    });
  }, [
    sectionData,
    accordionsSort,
    accordionIsOpen,
    offices,
    editQuestion,
    editSection,
    sortItems,
  ]);

  const onSubmitUpdatePage: SubmitHandler<PageTopTypes> = async (props) => {
    // remove aws wysiwyg img src and leave behind only keys
    const shortBody = postWYSIWYG(props.body);
    const cleanShortBody = cleanString(shortBody);

    await updatePage({
      variables: {
        input: {
          id: 'page-faq',
          ...props,
          updatedAt: new Date().toISOString(),
          label: props.title,
          headlineLow: props?.headline && props?.headline.toLowerCase(),
          body: shortBody,
          bodyLow: cleanShortBody.toLowerCase(),
        },
      },
    });

    // sortFunc
    const temp = { ...itemSorts };
    const sendToUpdate: { id: string; sort: number }[] = []; //* faq
    const sendToUpdateParent: { id: string; sort: number }[] = []; //* section

    if (temp) {
      Object.keys(temp).forEach((key) => {
        const parent = temp[key];
        if (parent.length > 0) {
          parent.forEach((page, index) => {
            if (page.index !== index) {
              if (key === 'sections') {
                sendToUpdateParent.push({
                  id: page.id || '',
                  sort: index,
                });
              } else {
                sendToUpdate.push({
                  id: page.id || '',
                  sort: index,
                });
              }
            }
          });
        }
      });
    }

    if (sendToUpdate.length > 0) {
      await itemSort({
        variables: { input: { tableName: 'FAQ', items: sendToUpdate } },
      });
    }
    if (sendToUpdateParent.length > 0) {
      await parentSort({
        variables: {
          input: { tableName: 'FAQsection', items: sendToUpdateParent },
        },
      });
    }

    //* reset
    setItemSorts({});
  };

  const submitAllForms = () => {
    // trigger submit function from refs
    if (pageFormRef.current) {
      pageFormRef.current.dispatchEvent(
        new Event('submit', { bubbles: true, cancelable: true })
      );
    }
  };

  // Migrate FAQ's from section to be deleted to another section
  const migrateChildrenFAQs = useCallback(
    async (migrateSectionId: string) => {
      const oldSection = sectionData.find((s) => s.id === sectionToDelete?.id);

      // Update Old sections FAQ's pointer to parent section
      await oldSection?.faqs.forEach(async (faq) => {
        await updateFAQ({
          variables: {
            input: {
              id: faq.id,
              question: faq.question,
              sectionID: migrateSectionId,
              answer: faq.answer,
              officeIDs: faq.officeIDs,
            },
          },
        });
      });

      // Once all are migrated, delete the old section
      await deleteFAQSection({
        variables: {
          input: {
            id: sectionToDelete?.id,
          },
        },
      });

      setTimeout(() => {
        setDeleteFormMigrate(null);
        setSectionToDelete(null);
      }, 500);
    },
    [sectionToDelete, deleteFAQSection, sectionData, updateFAQ]
  );

  if (loading || pageLoading) {
    return <LoaderView />;
  }

  return (
    <Container>
      <PageTop
        ref={pageFormRef}
        submitAllForms={submitAllForms}
        data={pageDataMemo}
        slugList={slugList}
        onSubmitUpdatePage={onSubmitUpdatePage}
        setIsDirtyForm={(isDirty: boolean) => {
          setIsDirtyForm(isDirty);
          setIsDirtyPageTop(isDirty);
        }}
        isDirtyForm={isDirtyForm}
      />
      {accordionData && (
        <SectionContainer
          title="Add and Edit FAQs"
          description="Manage FAQs to be displayed on the intranet."
          includePadding={false}
        >
          <Accordion
            {...accordionData}
            accordionIsOpen={accordionIsOpen}
            setAccordionIsOpen={setAccordionIsOpen}
            onDeleteRequest={(id) => setDeleteFormMigrate({ id })}
          />
        </SectionContainer>
      )}
      {creatingSection && (
        <SectionForm
          toast={toast}
          toggle={(getRefetch) => {
            if (getRefetch) {
              refetch();
            }
            setCreatingSection(false);
          }}
          length={sectionData.length}
          sectionData={sectionData}
        />
      )}
      {updatingSection && (
        <SectionForm
          toast={toast}
          toggle={(getRefetch) => {
            setOpenSection(false);
            if (getRefetch) {
              refetch();
            }
            setUpdatingSection(null);
          }}
          defaultValues={updatingSection.defaultValues}
          sectionData={sectionData}
        />
      )}
      {addingQuestion && (
        <QuestionForm
          toast={toast}
          parentId={addingQuestion.id}
          length={addingQuestion.length}
          toggle={(getRefetch) => {
            if (getRefetch) {
              refetch();
            }
            setAddingQuestion(null);
          }}
          offices={offices}
        />
      )}
      {updatingQuestion && (
        <QuestionForm
          toast={toast}
          toggle={(getRefetch) => {
            if (getRefetch) {
              refetch();
            }
            setUpdatingQuestion(null);
          }}
          defaultValues={updatingQuestion.defaultValues}
          offices={offices}
        />
      )}
      {deleteFormMigrate && sectionToDelete && (
        <MigrateForm
          // preventUnlock={true}
          toggle={() => {
            setOpenSection(false);
            // if (getRefetch) {
            //   refetch();
            // }
            setDeleteFormMigrate(null);
            setSectionToDelete(null);
          }}
          toggleDelete={() => {
            setDeleteFormMigrate(null);
            setSectionToDelete(null);
          }}
          section={sectionToDelete}
          sectionData={sectionData}
          onMigrate={async (migrateSectionId) => {
            migrateChildrenFAQs(migrateSectionId);
          }}
        />
      )}
      {deleteForm && (
        <DeleteForm
          toast={toast}
          toggle={async (getRefetch) => {
            if (getRefetch) {
              await refetch();
            }
            setDeleteForm(null);
          }}
          {...deleteForm}
          sectionData={sectionData}
        />
      )}
    </Container>
  );
};

export default withFeature('faqs')(FAQsContainer);
