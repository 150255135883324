import Modal from 'containers/Modal';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { IProps } from './HolidayForm.types';
import UnsavedModal from 'components/UnsavedModal/UnsavedModal';
import { useForm } from 'react-hook-form';
import {
  ButtonContainer,
  CancelButton,
  DeleteButton,
  Form,
  LineBreak,
  SubmitButton,
} from './styles';
import TextInput from 'components/FormComponents/TextInput';
import DateSelector from 'components/FormComponents/DateSelector';
import Toggle from 'components/FormComponents/Toggle';
import * as mutations from 'graphql/mutations';
import { gql, useMutation } from '@apollo/client';
import dayjs from 'dayjs';
import DeleteForm from 'components/Forms/DeleteForm/DeleteForm';

const CREATE_HOLIDAY = gql(mutations.createHoliday);
const UPDATE_HOLIDAY = gql(mutations.updateHoliday);

const HolidayForm = ({
  toast,
  toggle,
  defaultValues,
  yearID,
  length,
  year,
}: IProps): JSX.Element => {
  const [deletingHoliday, setDeletingHoliday] = useState(false);
  const [checkUnsaved, setCheckUnsaved] = useState(false);
  const [range, setRange] = useState(false);
  const { id } = defaultValues || {};
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    clearErrors,
    watch,
    formState: { errors, isDirty },
  } = useForm<any>({
    defaultValues,
  });

  const startOfYear = dayjs().year(year).startOf('year').toISOString();
  const endOfYear = dayjs().year(year).endOf('year').toISOString();

  const [createHoliday] = useMutation(CREATE_HOLIDAY, {
    onCompleted: () => {
      toast.success('Holiday created');
      toggle();
    },
    onError: () => {
      toast.error('Error creating holiday');
    },
  });

  const [updateHoliday] = useMutation(UPDATE_HOLIDAY, {
    onCompleted: () => {
      toast.success('Holiday updated');
      toggle();
    },
    onError: () => {
      toast.error('Error updating holiday');
    },
  });

  useEffect(() => {
    if (!defaultValues) return;
    if (
      !defaultValues?.startDate ||
      !(
        dayjs(defaultValues?.startDate).isSame(
          dayjs(defaultValues?.endDate),
          'day'
        ) || !defaultValues?.endDate
      )
    ) {
      setRange(true);
    }
  }, [defaultValues]);

  return (
    <>
      <Modal
        id="sectionModal"
        title={id ? 'Edit Holiday' : 'Add Holiday'}
        toggle={() => {
          if (isDirty) {
            setCheckUnsaved(true);
          } else {
            toggle();
          }
        }}
      >
        <Form
          noValidate
          onSubmit={handleSubmit(async (input) => {
            console.log('Holiday Form: ', input);
            if (range && !input.endDate) {
              setError('endDate', { type: 'manual' });
            }
            if (id) {
              await updateHoliday({
                variables: {
                  input: {
                    id,
                    title: input.title,
                    startDate: dayjs(input.startDate).toISOString(),
                    endDate: dayjs(
                      range ? input.endDate : input.startDate
                    ).toISOString(),
                    disclaimer: input.disclaimer,
                  },
                },
              });
            } else {
              await createHoliday({
                variables: {
                  input: {
                    title: input.title,
                    startDate: dayjs(input.startDate).toISOString(),
                    endDate: dayjs(
                      range ? input.endDate : input.startDate
                    ).toISOString(),
                    disclaimer: input.disclaimer,
                    yearID,
                    sort: length,
                  },
                },
              });
            }
          })}
        >
          <TextInput
            name="title"
            id="title"
            placeholder="Enter Holiday Name Here"
            maxLength={50}
            required={true}
            setValue={setValue}
            hideRequired={true}
            label="Holiday Name"
            register={register}
            setError={(title, error) => setError('title', error)}
            clearErrors={() => clearErrors('title')}
            error={!!errors?.title}
            errorMessage="Enter a holiday title"
            defaultValue={defaultValues?.title || ''}
          />
          <LineBreak />
          <Toggle
            label="Set Range"
            id="range"
            name="range"
            checked={range}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              const checked = e.target.checked;
              setRange(checked);
              if (!checked) {
                clearErrors('incorrectDate');
              }
            }}
          />
          <DateSelector
            name="startDate"
            label={range ? 'From Date' : 'Date'}
            register={register}
            setError={(name, error) => setError('startDate', error)}
            clearErrors={() => clearErrors('startDate')}
            required={true}
            setValue={(name, value) => setValue(name, value)}
            defaultValue={defaultValues?.startDate || undefined}
            error={!!(errors?.startDate || errors?.incorrectDate)}
            errorMessage={
              errors?.incorrectDate
                ? 'Set a correct date range'
                : 'Enter a Start Date'
            }
            minDate={startOfYear}
            maxDate={endOfYear}
            handleChange={(v) => {
              const endDate = watch('endDate');
              if (range && endDate) {
                if (dayjs(v).isSameOrAfter(dayjs(endDate), 'date')) {
                  setError('incorrectDate', { type: 'custom' });
                } else {
                  clearErrors('incorrectDate');
                }
              }
            }}
          />
          {range && (
            <DateSelector
              name="endDate"
              label="To Date"
              register={register}
              setError={(name, error) => setError('endDate', error)}
              clearErrors={() => clearErrors('endDate')}
              required={false}
              setValue={(name, value) => setValue(name, value)}
              defaultValue={defaultValues?.endDate || undefined}
              error={!!(errors?.endDate || errors?.incorrectDate)}
              errorMessage={
                errors?.incorrectDate
                  ? 'Set a correct date range'
                  : 'Enter a End Date'
              }
              minDate={startOfYear}
              handleChange={(v) => {
                const startDate = watch('startDate');
                if (range && startDate) {
                  if (dayjs(v).isSameOrBefore(dayjs(startDate), 'date')) {
                    setError('incorrectDate', { type: 'custom' });
                  } else {
                    clearErrors('incorrectDate');
                  }
                }
              }}
            />
          )}
          <LineBreak />
          <TextInput
            name="disclaimer"
            id="disclaimer"
            placeholder="Enter Disclaimer Here"
            maxLength={50}
            optional={true}
            label="Disclaimer"
            setValue={setValue}
            register={register}
            setError={(disclaimer, error) => setError('disclaimer', error)}
            clearErrors={() => clearErrors('disclaimer')}
            error={!!errors?.disclaimer}
            errorMessage="Enter a holiday disclaimer"
            defaultValue={defaultValues?.disclaimer || ''}
          />
          <ButtonContainer>
            <SubmitButton type="submit">
              {id ? 'Save' : 'Create Holiday'}
            </SubmitButton>
            <CancelButton
              type="button"
              tertiary
              onClick={() => {
                if (isDirty) {
                  setCheckUnsaved(true);
                } else {
                  toggle();
                }
              }}
            >
              Cancel
            </CancelButton>
            {id && (
              <DeleteButton
                type="button"
                delete
                onClick={() => setDeletingHoliday(true)}
              >
                Delete Holiday
              </DeleteButton>
            )}
          </ButtonContainer>
        </Form>
      </Modal>
      {checkUnsaved && (
        <UnsavedModal
          preventUnlock={true}
          cancel={() => setCheckUnsaved(false)}
          close={toggle}
        />
      )}
      {deletingHoliday && (
        <DeleteForm
          preventUnlock={true}
          type="holiday"
          id={defaultValues?.id ? defaultValues?.id : ''}
          toast={toast}
          toggle={toggle}
          toggleDelete={() => setDeletingHoliday(false)}
        />
      )}
    </>
  );
};

export default HolidayForm;
