import DateSelector from 'components/FormComponents/DateSelector';
import { IconTrash } from 'components/IconsView';
import { MileageItem } from '../../index';
import dayjs from 'dayjs';
import React from 'react';
import { CurrencyInput, HiddenInput, Row, TrashButton } from './styles';

interface Props {
  methods: any;
  handleTrash: (arg1: string, arg2: number) => void;
  handleEmptyTrash: (arg: number) => void;
  moreThanOne: boolean;
  zIndex: number;
  index: number;
  defaultValues: any;
  mileageList: MileageItem[];
}

const RateRow = ({
  methods,
  handleTrash,
  handleEmptyTrash,
  moreThanOne,
  zIndex,
  index,
  defaultValues,
  mileageList,
}: Props): JSX.Element => {
  return (
    <Row style={{ zIndex: zIndex }}>
      <DateSelector
        {...methods}
        allowWeekends={true}
        name={`row[${index}].date`}
        id="date"
        label="Effective Date"
        required={true}
        defaultValue={defaultValues?.date || ''}
        mileageList={mileageList}
        error={
          methods?.formState?.errors?.row?.[index]?.date ||
          methods?.formState?.errors?.row?.[index]?.sameDate
        }
        errorMessage={
          methods?.formState?.errors?.row?.[index]?.sameDate
            ? 'No overlapping dates'
            : null
        }
        handleChange={(value) => {
          methods.clearErrors();

          const temp = mileageList.map((obj, i) => {
            if (i === index) {
              return Object.assign({}, obj, { date: value });
            }
            return obj;
          });

          temp.map((obj, i) => {
            if (i !== index) {
              if (
                dayjs(obj.date).format('MM/DD/YYYY') ===
                dayjs(value).format('MM/DD/YYYY')
              ) {
                methods.setError(`row[${index}].sameDate`, { type: 'manual' });
                methods.setError(`row[${i}].sameDate`, { type: 'manual' });
              }
            }
          });
        }}
      />
      <CurrencyInput
        {...methods}
        name={`row[${index}].rate`}
        id={`row[${index}].rate`}
        label="Rate"
        required={true}
        pattern="[^0-9.]"
        placeholder="0.000"
        type="number"
        defaultValue={defaultValues?.rate || ''}
        error={methods?.formState?.errors?.row?.[index]?.rate}
        maxLength={5}
        hideLength
      />
      <HiddenInput
        {...methods}
        name={`row[${index}].id`}
        id="id"
        label="id"
        defaultValue={defaultValues?.id || ''}
      />

      {moreThanOne && (
        <TrashButton
          type="button"
          data-testid="trash"
          onClick={() => {
            if (
              !methods.watch().row[index].date &&
              !methods.watch().row[index].rate
            ) {
              methods.clearErrors(`row[${index}]`);
              handleEmptyTrash(index);
            } else {
              methods.clearErrors(`row[${index}]`);
              handleTrash(defaultValues?.id, index);
            }
          }}
        >
          <IconTrash />
        </TrashButton>
      )}
    </Row>
  );
};

export default RateRow;
