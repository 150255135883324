import { Holiday, Year } from 'API';
import AccordionItems from 'components/Accordion/AccordionItem/AccordionItem.types';
import dayjs from 'dayjs';

interface IFormat {
  years: Year[];
  accordionIsOpen: any[];
  createYear: (length: number) => void;
  updateYear: (id: string) => void;
  deleteYear: ({ id, type }: { id: string; type: string }) => void;
  createHoliday: (length: number, id: string, year: string) => void;
  updateHoliday: (id: string, year: string) => void;
  deleteHoliday: ({ id, type }: { id: string; type: string }) => void;
}

export const formatDataForAccordion = ({
  years,
  accordionIsOpen,
  createYear,
  updateYear,
  deleteYear,
  createHoliday,
  updateHoliday,
  deleteHoliday,
}: IFormat): any => {
  const formatYear = (year: Year | any, index: number) => {
    const holidays: (Holiday | null)[] = year.holidays?.items || [];

    const accordionItems: AccordionItems[] = holidays
      ?.slice()
      ?.sort((x: any, y: any) => {
        if (dayjs(x.startDate).isAfter(dayjs(y.startDate))) {
          return 1;
        } else if (dayjs(x.startDate).isBefore(dayjs(y.startDate))) {
          return -1;
        }
        return 0;
      })
      .map((h: any, i) => {
        const formatDate = !h.startDate
          ? ''
          : dayjs(h.startDate).isSame(dayjs(h.endDate), 'day') || !h.endDate
          ? dayjs(h.startDate).format('MM.DD.YYYY')
          : `${dayjs(h.startDate).format('MM.DD.YYYY')} - ${dayjs(
              h.endDate
            ).format('MM.DD.YYYY')}`;
        return {
          id: h.id,
          index: i,
          originalIndex: i,
          title: h.title || '',
          secondaryTitle: formatDate,
          editFunc: () => {
            updateHoliday(h.id, year.title);
          },
          deleteFunc: () => {
            deleteHoliday({ id: h.id, type: 'holiday' });
          },
        };
      });

    return {
      id: year.id,
      show: accordionIsOpen.includes(year.id),
      index: index,
      originalIndex: index,
      title: year.title || '',
      // show: year.show,
      editFunc: () => {
        updateYear(year.id);
      },
      deleteFunc: () => {
        console.log(year.id);
        deleteYear({ id: year.id, type: 'year' });
      },
      addFunc: () => {
        createHoliday(holidays.length || 0, year.id, year.title);
      },
      addFuncText: `Add Additional Holiday within ${year.title}`,
      accordionItems,
    };
  };

  return {
    accordionContainer: years
      .slice()
      ?.sort((x: Year, y: Year) => {
        if (dayjs(x.date).isAfter(dayjs(y.date))) {
          return -1;
        } else if (dayjs(x.date).isBefore(dayjs(y.date))) {
          return 1;
        }
        return 0;
      })
      .map(formatYear),
    addFunc: () => {
      createYear(years?.length + 1);
    },
    addFuncText: 'Add Additional Year',
  };
};
