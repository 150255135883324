// NOTE: Navigation links assume React Router is used

import React from 'react';
import { useFeatures } from 'flagged';
import {
  Container,
  LogoWrapper,
  Title,
  StyledLink,
  UserContainer,
  UserLabel,
  UserLogout,
  HomeLink,
  CatPage,
  UserInfo,
  ProfilePicture,
  Nav,
  Blob,
  BlobContainer,
} from './styles';
import {
  Container2,
  UserContainer2,
  StyledLink2,
  CatPage2,
} from './styles-new';
import isTouchDevice from 'is-touch-device';
import { ReactComponent as Logo } from 'images/icons/logo.svg';
import { ReactComponent as Profile } from 'images/icons/profile.svg';
import * as Types from './Navigation.types';
const touchable = isTouchDevice();

const User = ({ signOut, label, picture }: Types.User) => {
  const features = useFeatures();

  const formatName = (name: string) => {
    if (typeof name === 'string') {
      const names = name.split(' ');
      const firstName = names[0];
      const lastName = names[names.length - 1];
      return `${firstName} ${lastName.charAt(0)}.`;
    }
  };

  const UserContainerEle = features.navV2 ? UserContainer2 : UserContainer;

  return (
    <UserContainerEle>
      {picture ? (
        <ProfilePicture
          referrerPolicy="no-referrer"
          src={picture}
          alt="Google Avatar"
        />
      ) : (
        <Profile />
      )}
      <UserInfo>
        <UserLabel>{formatName(label)}</UserLabel>
        <UserLogout
          data-touchable={touchable}
          className="tertiary"
          onClick={signOut}
        >
          {features.navV2 ? 'Log out' : 'Sign out'}
        </UserLogout>
      </UserInfo>
    </UserContainerEle>
  );
};

const Link = ({ label, link }: Types.Link): JSX.Element => {
  return <StyledLink to={link}>{label}</StyledLink>;
};

const Navigation = ({
  title,
  links,
  user,
  userPicture,
  signOut,
}: Types.Navigation): JSX.Element => {
  const features = useFeatures();

  const ContainerEle = features.navV2 ? Container2 : Container;
  const StyledLinkEle = features.navV2 ? StyledLink2 : StyledLink;
  const CatPageEle = features.navV2 ? CatPage2 : CatPage;

  return (
    <ContainerEle>
      <LogoWrapper>
        <HomeLink to="/">
          <Logo />
          <BlobContainer>
            <Blob />
          </BlobContainer>
        </HomeLink>
      </LogoWrapper>
      <Title>{title}</Title>
      <User label={user} picture={userPicture} signOut={signOut}></User>
      <Nav>
        {features.navV2 ? null : <CatPageEle>Pages</CatPageEle>}
        <StyledLinkEle to="/" exact>
          {features.navV2 ? 'Page Manager' : 'All Pages'}
        </StyledLinkEle>
        {features.navV2 ? null : (
          <>
            <StyledLinkEle to="/page-manage-offices" exact>
              Office Management
            </StyledLinkEle>
            <StyledLinkEle to="/page-suggested-search" exact>
              Suggested Search
            </StyledLinkEle>
          </>
        )}
        <CatPageEle>
          {features.navV2 ? 'Configure' : 'Specialty Pages'}
        </CatPageEle>
        {features.navV2 ? (
          <>
            <StyledLinkEle to="/page-manage-offices" exact>
              Office Management
            </StyledLinkEle>
            <StyledLinkEle to="/page-suggested-search" exact>
              Suggested Search
            </StyledLinkEle>
            <StyledLinkEle to="/page-404" exact>
              404
            </StyledLinkEle>
          </>
        ) : (
          <>
            {links.map((link, index) => (
              <Link {...link} key={index} />
            ))}
          </>
        )}
      </Nav>
    </ContainerEle>
  );
};

export default Navigation;
