import React, {
  useEffect,
  FC,
  useCallback,
  useMemo,
  useState,
  useRef,
} from 'react';
import Accordion from 'components/Accordion';
import AccordionTypes from 'components/Accordion/Accordion.types';
import { formatDataForAccordion } from './helpers/format';
import MemberForm from './Forms/MemberForm/MemberForm';
import * as queries from 'graphql/queries';
import * as mutations from 'graphql/mutations';
import { useMutation, useQuery, gql, useLazyQuery } from '@apollo/client';

import { Container } from './styles';

import Debug from 'debug';
import PageTop from 'components/PageTop';
import SectionContainer from 'components/SectionContainer';
import OfficeForm from './Forms/OfficeForm/OfficeForm';
import DeleteForm from 'components/Forms/DeleteForm/DeleteForm';
import { listPages } from 'utils/queries';
import { SubmitHandler } from 'react-hook-form';
import PageTopTypes from 'components/PageTop/PageTop.types';
import { cleanString, getSlugs, postWYSIWYG } from 'utils/functions';
import AccordionItems from 'components/Accordion/AccordionItem/AccordionItem.types';
import { arrayMove } from 'react-sortable-hoc';
import {
  LIST_LOCATION_BY_TYPE_MEMBER,
  LIST_LOCATION_BY_TYPE_FAQ,
} from './queries';
import { Location, Member } from 'API';
import { ISorting } from 'types/SortingType';
import { SlugI } from 'types/SlugListType';
import LoaderView from 'components/LoaderView';

const debug = Debug('fnd:faqs:index');

export const LIST_PAGES = gql(listPages);
export const GET_PAGE = gql(queries.getPage);
const GET_MEMBER = gql(queries.getMember);
const LIST_OFFICES = gql(queries.listOffices);
export const UPDATE_MEMBER = gql(mutations.updateMember);
export const SORT_ITEMS = gql(mutations.sortItemsV2);
export const VIEW_ITEMS = gql(mutations.viewItems);
export const UPDATE_PAGE = gql(mutations.updatePage);

interface ITeamMembers {
  toast: {
    success: (str: string) => void;
    error: (str: string) => void;
  };
}

const TeamMembers: FC<ITeamMembers> = ({ toast }): JSX.Element => {
  //* STATES
  const [accordionsSort, setAccordionsSort] = useState<ISorting[]>([]);
  const [itemSorts, setItemSorts] = useState<{
    [key: string]: AccordionItems[];
  }>({});
  const [itemViews, setItemViews] = useState<{
    [key: string]: { newStatus: boolean; oldStatus: boolean };
  }>({});
  const [parentViews, setParentViews] = useState<{
    [key: string]: { newStatus: boolean; oldStatus: boolean };
  }>({});
  const pageFormRef = useRef<HTMLFormElement>(null);
  const [creatingOffice, setCreatingOffice] = useState(false);
  const [updatingOffice, setUpdatingOffice] = useState<{
    id: string;
    name: string;
    officeId: string;
    hasChildren: boolean;
  } | null>(null);
  const [deleteForm, setDeleteForm] = useState<{
    id: string;
    officeID?: string;
    type: string;
  } | null>(null);
  const [isDirtyForm, setIsDirtyForm] = useState(false);
  const [isDirtyPageTop, setIsDirtyPageTop] = useState(false);
  const [createMember, setCreateMember] = useState<null | {
    officeID: string;
    length: number;
  }>(null);
  const [updateMember, setUpdateMember] = useState<Member | null>(null);
  const [accordionIsOpen, setAccordionIsOpen] = useState<any[]>([]);

  //* QUERIES
  // const { data: slugData } = useQuery(LIST_PAGES);

  const {
    loading: pageLoading,
    refetch: pageRefetch,
    data: pageData,
  } = useQuery(GET_PAGE, {
    variables: {
      id: 'page-team-members',
    },
  });

  const { data: locationData, refetch } = useQuery(
    LIST_LOCATION_BY_TYPE_MEMBER,
    {
      fetchPolicy: 'network-only',
    }
  );

  const { data: faqData } = useQuery(LIST_LOCATION_BY_TYPE_FAQ, {
    fetchPolicy: 'no-cache',
  });

  const { data: officeData } = useQuery(LIST_OFFICES);

  const [getMember] = useLazyQuery(GET_MEMBER, {
    onCompleted: ({ getMember: mem }) => {
      setUpdateMember(mem);
    },
    onError: () => {
      toast.error('An error loading team member content has occurred');
    },
  });

  //* MEMOS FOR QUERIES
  const data = useMemo(() => {
    return pageData?.getPage;
  }, [pageData]);

  const members = useMemo(() => {
    return locationData?.listLocationByType?.items;
  }, [locationData]);

  const faqLocations = useMemo(() => {
    return faqData?.listLocationByType?.items;
  }, [faqData]);

  const offices = useMemo(() => {
    return officeData?.listOffices?.items;
  }, [officeData]);

  const [slugList, setSlugs] = useState<SlugI[]>([]);
  const doFirst = async () => {
    const temp = await getSlugs();
    setSlugs(temp);
  };
  useEffect(() => {
    doFirst();
  }, []);

  //* MUTATIONS
  const [updatePage] = useMutation(UPDATE_PAGE, {
    onCompleted: () => {
      toast.success('Page updated');
      pageRefetch();
    },
    onError: () => {
      toast.error('Error updating page');
    },
  });

  const [itemSort] = useMutation(SORT_ITEMS, {
    onCompleted: () => {
      toast.success('Members sorted');
      pageRefetch();
    },
    onError: () => {
      toast.error('Error sorting members');
    },
  });

  const [parentSort] = useMutation(SORT_ITEMS, {
    onCompleted: () => {
      toast.success('Locations sorted');
    },
    onError: () => {
      toast.error('Error sorting locations');
    },
  });

  const [itemView] = useMutation(VIEW_ITEMS, {
    onCompleted: () => {
      toast.success('Locations and Members updated');
    },
    onError: () => {
      toast.error('Error updating locations and members');
    },
  });

  //* FUNCS

  useEffect(() => {
    if (!members) return;
    if (accordionsSort.length > 0) {
      const temp = [...accordionsSort];
      // update when something is added or removed
      if (members.length !== accordionsSort.length) {
        // something was added or deleted at the location level
        if (members.length > accordionsSort.length) {
          // a location was added
          const addedItem: Location[] = members.filter(
            (l: Location) => !accordionsSort.some((as) => as.id === l.id)
          );
          console.log('location addedItem', addedItem);
          if (addedItem) {
            temp.push({ id: addedItem[0].id, items: [] });
          }
        } else {
          // a locations was removed
          const missingItem = accordionsSort.findIndex(
            (as) => !members.some((l: Location) => l.id === as.id)
          );
          console.log('location missingItem', missingItem);
          if (missingItem > -1) {
            temp.splice(missingItem, 1);
          }
        }
      } else {
        //check members then
        members.map((l: Location, lIndex: number) => {
          const officeMembers = l.office.members?.items || [];
          const aSectionSort = accordionsSort[lIndex].items;
          if (officeMembers.length !== aSectionSort?.length) {
            // something was added or deleted
            if ((officeMembers.length || 0) > (aSectionSort?.length || 0)) {
              // a member was added
              const addedItem: Member[] =
                officeMembers.filter(
                  (s: Member) => !aSectionSort?.includes(s.id)
                ) || [];
              console.log('member addedItem', addedItem);
              if (addedItem) {
                temp[lIndex].items?.push(addedItem[0].id);
              }
            } else {
              // a member was deleted
              const missingItem =
                officeMembers.findIndex(
                  (s: Member) => !aSectionSort?.includes(s.id)
                ) || -1;
              console.log('member missingItem', missingItem);
              if (missingItem > -1) {
                temp[lIndex].items?.splice(missingItem, 1);
              }
            }
          }
        });
      }
      console.log(temp);
      setAccordionsSort(temp);
    } else {
      // create the sorting object state
      const temp: ISorting[] = [];
      members
        .slice()
        ?.sort((x: Location, y: Location) => {
          return (x.sort || 0) - (y.sort || 0);
        })
        .map((l: Location) => {
          const local: ISorting = { id: l.id, items: [] };
          l.office.members?.items
            .slice()
            ?.sort((x: Member, y: Member) => {
              return (x.sort || 0) - (y.sort || 0);
            })
            .map((m: Member) => {
              const member: string = m.id;

              local.items?.push(member);
            });
          temp.push(local);
        });
      console.log(temp);
      setAccordionsSort(temp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [members]);

  const activeMember = useCallback(
    async (id: string, newStatus: boolean, prevStatus: boolean) => {
      await setItemViews((prev) => {
        const newView = { ...prev };
        if (newStatus === prevStatus) {
          delete newView[id];
        } else {
          newView[id] = { newStatus, oldStatus: prevStatus };
        }
        return newView;
      });
    },
    []
  );

  const activeLocation = useCallback(
    async (id: string, newStatus: boolean, prevStatus: boolean) => {
      await setParentViews((prev) => {
        const newView = { ...prev };
        if (newStatus === prevStatus) {
          delete newView[id];
        } else {
          newView[id] = { newStatus, oldStatus: prevStatus };
        }
        return newView;
      });
    },
    []
  );

  const sortMembers = useCallback(
    async (
      {
        oldIndex,
        newIndex,
      }: {
        oldIndex: number;
        newIndex: number;
      },
      items?: any[],
      id?: string
    ) => {
      setItemSorts((prev) => {
        const newSort = { ...prev };
        if (id && items) {
          /* Checking to see if the array has been sorted. If it has, it will add it to the pageSorts
          object. If it hasn't, it will delete it from the pageSorts object. */
          const probableChange = arrayMove(items, oldIndex, newIndex);
          const differenceAry = probableChange.slice(1).map(function (n, i) {
            return n.index - probableChange[i].index;
          });
          const isDifference = differenceAry.some((value) => value !== 0);
          if (isDifference) {
            newSort[id] = probableChange;
          } else {
            delete newSort[id];
          }
          return newSort;
        }
        return newSort;
      });
    },
    []
    // [refetch]
  );

  const callMember = useCallback(
    (id: string) => {
      getMember({ variables: { id } });
    },
    [getMember]
  );

  const accordionData: AccordionTypes | null = useMemo(() => {
    if (!members) {
      return null;
    }

    return formatDataForAccordion({
      locations: members,
      accordionsSort,
      accordionIsOpen: accordionIsOpen,
      addMember: setCreateMember,
      getMember: callMember,
      deleteMember: setDeleteForm,
      activeMember,
      activeOffice: setCreatingOffice,
      updateLocation: setUpdatingOffice,
      activeLocation,
      deleteLocation: setDeleteForm,
      sortMembers,
      faqLocations,
    });
  }, [
    members,
    accordionsSort,
    accordionIsOpen,
    callMember,
    activeMember,
    activeLocation,
    sortMembers,
    faqLocations,
  ]);

  debug('accordionData: %o', accordionData);

  useEffect(() => {
    // check if items were sorted or changed views
    if (
      Object.keys(itemSorts).length > 0 ||
      Object.keys(itemViews).length > 0 ||
      Object.keys(parentViews).length > 0
    ) {
      setIsDirtyForm(true);
    } else {
      // if not then check if PageTop is dirty too
      if (!isDirtyPageTop) {
        // if not then it was just the accordion that was dirty so update isDirty to false now
        setIsDirtyForm(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemSorts, itemViews, parentViews]);

  const onSubmitUpdatePage: SubmitHandler<PageTopTypes> = async (props) => {
    // remove aws wysiwyg img src and leave behind only keys
    const shortBody = postWYSIWYG(props.body);
    const cleanShortBody = cleanString(shortBody);

    updatePage({
      variables: {
        input: {
          id: 'page-team-members',
          ...props,
          updatedAt: new Date().toISOString(),
          label: props.title,
          headlineLow: props?.headline && props?.headline.toLowerCase(),
          body: shortBody,
          bodyLow: cleanShortBody.toLowerCase(),
        },
      },
    });

    // sort func
    const temp = { ...itemSorts };
    console.log('itemSorts temp: ', temp);
    const sendToUpdate: { id: string; sort: number }[] = [];
    const sendToUpdateParent: { id: string; sort: number }[] = [];
    if (temp) {
      Object.keys(temp).forEach((key) => {
        const parent = temp[key];
        if (parent.length > 0) {
          parent.forEach((page, index) => {
            if (page.index !== index) {
              if (key === 'locations') {
                sendToUpdateParent.push({
                  id: page.id || '',
                  sort: index,
                });
              } else {
                sendToUpdate.push({
                  id: page.id || '',
                  sort: index,
                });
              }
            }
          });
        }
      });
    }
    console.log('sendToUpdateParent', sendToUpdateParent);
    console.log('sendToUpdate', sendToUpdate);

    if (sendToUpdate.length > 0) {
      console.log('sending update to itemSort...');
      await itemSort({
        variables: { input: { tableName: 'Member', items: sendToUpdate } },
      });
    }
    if (sendToUpdateParent.length > 0) {
      await parentSort({
        variables: {
          input: { tableName: 'Location', items: sendToUpdateParent },
        },
      });
    }
    setItemSorts({});

    // view func parents
    const tempParentView = { ...parentViews };
    const sendToUpdateParentView: { id: string; active: boolean }[] = [];

    if (tempParentView) {
      Object.keys(tempParentView).forEach((key) => {
        const item = tempParentView[key];
        if (item.newStatus !== item.oldStatus) {
          sendToUpdateParentView.push({
            id: key,
            active: item.newStatus,
          });
        }
      });
    }
    console.log('sendToUpdateParentView', sendToUpdateParentView);

    if (sendToUpdateParentView.length > 0) {
      await itemView({
        variables: {
          input: {
            tableName: 'Location',
            status: 'active',
            items: sendToUpdateParentView,
          },
        },
      });
    }

    // view func items
    const tempView = { ...itemViews };
    const sendToUpdateView: { id: string; active: boolean }[] = [];

    if (tempView) {
      Object.keys(tempView).forEach((key) => {
        const item = tempView[key];
        if (item.newStatus !== item.oldStatus) {
          sendToUpdateView.push({
            id: key,
            active: item.newStatus,
          });
        }
      });
    }
    console.log('sendToUpdateView', sendToUpdateView);

    if (sendToUpdateView.length > 0) {
      await itemView({
        variables: {
          input: {
            tableName: 'Member',
            status: 'active',
            items: sendToUpdateView,
          },
        },
      });
    }

    // refetch();
  };

  const submitAllForms = () => {
    // trigger submit function from refs
    if (pageFormRef.current) {
      pageFormRef.current.dispatchEvent(
        new Event('submit', { bubbles: true, cancelable: true })
      );
    }
  };
  if (pageLoading) {
    return <LoaderView />;
  }

  return (
    <Container>
      <PageTop
        ref={pageFormRef}
        submitAllForms={submitAllForms}
        onSubmitUpdatePage={onSubmitUpdatePage}
        data={data}
        slugList={slugList}
        setIsDirtyForm={(isDirty: boolean) => {
          setIsDirtyForm(isDirty);
          setIsDirtyPageTop(isDirty);
        }}
        isDirtyForm={isDirtyForm}
      />
      <SectionContainer
        includePadding={false}
        title="Add And Edit Team Members"
        description="Click on the content you would like to edit"
      >
        {!accordionData ? (
          <></>
        ) : (
          <Accordion
            {...accordionData}
            accordionIsOpen={accordionIsOpen}
            setAccordionIsOpen={setAccordionIsOpen}
          />
        )}
      </SectionContainer>
      {createMember && (
        <MemberForm
          toast={toast}
          toggle={(refetchMembers?: boolean) => {
            if (refetchMembers) {
              refetch();
            }
            setCreateMember(null);
          }}
          {...createMember}
        />
      )}
      {updateMember && (
        <MemberForm
          toast={toast}
          toggle={(refetchMembers?: boolean) => {
            if (refetchMembers) {
              refetch();
            }
            setUpdateMember(null);
          }}
          defaultValues={updateMember}
        />
      )}
      {creatingOffice && (
        <OfficeForm
          toast={toast}
          length={members?.length}
          offices={offices}
          faqLocations={faqLocations}
          toggle={(refetchLocations?: boolean) => {
            if (refetchLocations) {
              refetch();
            }
            setCreatingOffice(false);
          }}
        />
      )}
      {updatingOffice && (
        <OfficeForm
          toast={toast}
          defaultValues={updatingOffice}
          offices={offices}
          faqLocations={faqLocations}
          toggle={async (refetchLocations?: boolean) => {
            if (refetchLocations) {
              await refetch();
            }
            setUpdatingOffice(null);
          }}
        />
      )}
      {deleteForm && (
        <DeleteForm
          toast={toast}
          toggle={async () => {
            await refetch();
            setDeleteForm(null);
          }}
          {...deleteForm}
        />
      )}
    </Container>
  );
};

export default TeamMembers;
