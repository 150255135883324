import { Page, PageStatus, QuickLink } from 'API';
import AccordionItems from 'components/Accordion/AccordionItem/AccordionItem.types';

interface IFormat {
  pages: Page[];
  addSection: ({ length }: { length: number }) => void;
  updateSection: (id: string) => void;
  activePage: (
    id: string,
    newStatus: boolean,
    prevStatus: PageStatus,
    childrenPages?: { id: string; status: PageStatus }[]
  ) => void;
  deletePage: ({
    id,
    type,
    pageIDs,
  }: {
    id: string;
    type: string;
    pageIDs?: string[];
  }) => void;
  sortSection: (
    {
      oldIndex,
      newIndex,
    }: {
      oldIndex: number;
      newIndex: number;
    },
    items?: any[],
    id?: string
  ) => void;
  addPage: ({ length, pageId }: { length: number; pageId: string }) => void;
  updatePage: (url: string) => void;
  sortPage: (
    {
      oldIndex,
      newIndex,
    }: {
      oldIndex: number;
      newIndex: number;
    },
    items?: any[],
    id?: string
  ) => void;
  handleQuicklinks: ({
    quicklinks,
    page,
  }: {
    quicklinks: QuickLink[];
    page: Page;
  }) => void;
}

export const formatDataForAccordion = ({
  pages,
  addSection,
  updateSection,
  deletePage,
  sortSection,
  activePage,
  addPage,
  updatePage,
  sortPage,
  handleQuicklinks,
}: IFormat): any => {
  const checkPage = (page: Page, func?: string): boolean => {
    if (page.id === 'page-home') {
      return false;
    }
    // ! CHANGE LATER, hide faq and freelance for now
    if (
      func === 'active' &&
      (page.layout === 'freelance' || page.layout === 'faq')
    ) {
      return true;
    }
    if (
      page.layout === 'plain' ||
      page.layout === 'tabbed' ||
      page.layout === 'officeTab'
    ) {
      return true;
    }
    if (page.layout === 'main') {
      return true;
    }
    return false;
  };

  const formatSection = (section: Page | any, index: number) => {
    const childrenIds: { id: string; status: PageStatus }[] = [];
    const accordionItems: AccordionItems[] = section.pages.items
      ?.filter((p: Page) => p.id !== 'page-applications')
      ?.sort((a: Page, b: Page) => {
        return (a.sort || 0) - (b.sort || 0);
      })
      .map((p: Page, i: number) => {
        if (checkPage(p, 'active')) {
          childrenIds.push({
            id: p.id,
            status: PageStatus[p?.status || 'disabled'],
          });
        }
        return {
          id: p.id,
          index: i,
          originalIndex: i,
          title: p.title,
          editFunc: () => {
            updatePage(
              p.layout === 'plain' ||
                p.layout === 'tabbed' ||
                p.layout === 'officeTab'
                ? `page/edit/${p.id}`
                : p.id
            );
          },
          ...(checkPage(p)
            ? {
                deleteFunc: () => {
                  if (
                    p?.referencedQuicklinks &&
                    p?.referencedQuicklinks?.items?.length > 0
                  ) {
                    handleQuicklinks({
                      quicklinks: p?.referencedQuicklinks.items,
                      page: p,
                    });
                  } else {
                    const pageIDs = p?.pages
                      ? p?.pages.items?.map((item) => {
                          return item.id;
                        })
                      : [];
                    deletePage({ id: p.id, type: 'page', pageIDs });
                  }
                },
              }
            : null),
          ...(checkPage(p, 'active')
            ? {
                activeFunc: (newActive: boolean) => {
                  activePage(p.id, newActive, p.status || PageStatus.disabled);
                },
              }
            : null),
          active: p.status === 'active',
          fakeActive:
            section.status === 'active'
              ? undefined
              : section.status === 'active',
        };
      });

    return {
      accordionItems,
      id: section.id,
      index: index,
      originalIndex: index,
      title: section.title,
      sortable: section.id !== 'page-home',
      editFunc: () => {
        updateSection(
          checkPage(section) ? `main/edit/${section.id}` : section.id
        );
      },
      ...(checkPage(section)
        ? {
            deleteFunc: () => {
              if (section.referencedQuicklinks.items?.length > 0) {
                handleQuicklinks({
                  quicklinks: section.referencedQuicklinks.items,
                  page: section,
                });
              } else {
                deletePage({ id: section.id, type: 'section' });
              }
            },
          }
        : null),
      ...(checkPage(section, 'active')
        ? {
            activeFunc: (newActive: boolean) => {
              activePage(section.id, newActive, section.status, childrenIds);
            },
          }
        : null),
      active: section.status === 'active',
      ...(section.id !== 'page-faq' && section.id !== 'page-home'
        ? {
            addFunc: () => {
              addPage({
                length: section.pages.items.length,
                pageId: section.id,
              });
            },
            addFuncText: `Add Additional Page to ${section.title}`,
          }
        : null),
      onSortEnd: (
        {
          oldIndex,
          newIndex,
        }: {
          oldIndex: number;
          newIndex: number;
        },
        items?: any[],
        id?: string
      ) => {
        sortPage({ oldIndex, newIndex }, items, id);
      },
    };
  };

  return {
    accordionContainer: pages
      .slice()
      ?.filter((page) => page.layout !== 'error')
      ?.sort((x: Page, y: Page) => {
        if (x.id === 'page-home') {
          return -1;
        } else {
          return (x.sort || 0) - (y.sort || 0);
        }
      })
      .map(formatSection),
    addFunc: () => {
      addSection({ length: pages.length || 0 });
    },
    addFuncText: 'Add Additional Section',
    onSortEnd: (
      {
        oldIndex,
        newIndex,
      }: {
        oldIndex: number;
        newIndex: number;
      },
      items?: any[]
    ) => {
      sortSection({ oldIndex, newIndex }, items, 'main');
    },
    type: 'pages',
  };
};
